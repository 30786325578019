import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { makeStyles } from '@material-ui/core/styles';
import { logOut } from '../applications/reducers/AuthSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ModalComponent from '../components/modalComponent/index.jsx';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

const styles = {
  actionBarContent: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  appBarContent: {
    flexDirection: 'row !important',
    height: '60px',
    paddingLeft: '20px',
  },
}
const useStyles = makeStyles(styles);

export default function Header(props) {
  const { drawerWidth, handleDrawerOpen, open } = props
  const classes = useStyles();
  const [openProfile, setOpenProfile] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const handleClickProfile = (event) => {
    setOpenConfirm(true);
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const disconnect = () => {
    dispatch(logOut());
    sessionStorage.removeItem('token');
    navigate('/login');
    setOpenConfirm(false)
  }
  
  return (
      <>
          {/* <CssBaseline /> */}
          <AppBar sx={{...(open ? { justifyContent: 'flex-end' } : { justifyContent: 'space-between'} )}} className={classes.appBarContent} position="fixed" open={open}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{...(open && { display: 'none' })}}
              >
                <MenuIcon />
              </IconButton>
              <div className={classes.actionBarContent}>
                <IconButton
                  color={window.innerWidth > 959 ? 'transparent' : 'white'}
                  aria-haspopup="true"
                  className={`kl-profil-btn`}
                >
                  <NotificationsIcon />
                </IconButton>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <IconButton variant="contained" {...bindTrigger(popupState)}>
                        <PersonIcon />
                      </IconButton>
                      <Menu sx={{top : '-10px' }} {...bindMenu(popupState)}>
                        <MenuItem onClick={handleClickProfile}>Déconnecter</MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
                {/* <Menu
                  id="fade-menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={handleClickProfile}>Profile</MenuItem>
                </Menu> */}
              </div>
          </AppBar>
          <ModalComponent
            openModal={openConfirm}
            close={() => setOpenConfirm(false)}
            title={'Déconnexion'}
            submit={() => disconnect()}
            actionTitle={'Confirmer'}
            modalContentBody={'Êtes-vous sûr de vouloir vous déconnecter?'}
          />
      </>
  )
}
