import React, { useState, useEffect } from "react";
import Table from '../../../components/table/index.jsx';
import moment from "moment";

function Register(props) {
  const { employee } = props;
  const [rows, setRows] = useState([]);
  const [story, setStory] = useState([]);

  useEffect(() => {
    const newRows = []
    employee.Holyday.forEach(el => {
      newRows.push({
        id: el.id,
        start: moment(el.start).format('DD MMMM YYYY') + ' ' + el.startJourney,
        end: moment(el.end).format('DD MMMM YYYY') + ' ' + el.endJourney,
        nbrDayConge: el.nbrDayConge,
        congeType: el.congeType,
      });
    });
    setRows(newRows);
    const newRowsStory = []
    employee.historique.forEach(el => {
      newRowsStory.push({
        id: el.id,
        date: moment(el.date).format('DD MMMM YYYY'),
        type: el.type,
        description: el.description,
      });
    });
    setStory(newRowsStory);
    // eslint-disable-next-line
  }, [])
  const columns = [
    {
        field: 'start',
        headerName: 'Date de début',
        align: 'center',
        headerAlign: 'center',
        flex: 1
    },
    {
        field: 'end',
        headerName: 'Date fin',
        align: 'center',
        headerAlign: 'center',
        flex: 1
    },
    {
        field: 'nbrDayConge',
        headerName: 'Nombre de jour(s)',
        align: 'center',
        headerAlign: 'center',
        flex: 1
    },
    {
      field: 'congeType',
      headerName: 'Type de congé',
      align: 'center',
      headerAlign: 'center',
      flex: 1
    },
  ]

  const columnsRegister = [
    {
      field: 'date',
      headerName: 'Date',
      align: 'center',
      headerAlign: 'center',
      flex: 1
    },
    {
      field: 'type',
      headerName: 'Type',
      align: 'center',
      headerAlign: 'center',
      flex: 1
    },
    {
      field: 'description',
      headerName: 'Description',
      align: 'center',
      headerAlign: 'center',
      flex: 1
    },
  ]

  return (
    <>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <h4 style={{ margin: 0 }}>Solde de Congé:</h4>
        &nbsp;
        <span>{' ' + employee.EmployeeSocietyInfo.length > 0 ? employee.EmployeeSocietyInfo[0].conges : '' }</span>
      </div>
      <Table columns={columns} rows={rows}/>
      <div style={{ display: 'flex', marginBottom: '20px', marginTop: '20px' }}>
        <h4 style={{ margin: 0 }}>Registre</h4>
      </div>
      <Table columns={columnsRegister} rows={story}/>
    </>
  )
}

export default Register;