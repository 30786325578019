import React, { useContext } from 'react';
import { AddEmployeeContext } from './index.jsx';

const Recap = () => {
    const { addEmployeeData } = useContext(AddEmployeeContext);

    return (
        <div>{JSON.stringify(addEmployeeData, null, 2)}</div>
    )
}

export default Recap
