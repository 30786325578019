import { Http } from '../../../applications/Http.js'

// class EmployeeService extends Service {
class AdvancesService {

    static async getAllAdvances() {
        return await Http.get('/api/advances/list');
    }
    static async getAllAdvancesLine() {
        return await Http.get('/api/advances/list-line');
    }
    // static async deleteEmployee(id) {
    //     return await Http.delete(`/api/employees/delete?id=${id}`);
    // }
    static async addAdvances(payload) {
        return await Http.put(`/api/advances/add`, payload);
    }
    static async getAllEmployee() {
        return await Http.get('/api/employees/list');
    }
    static async validateAdvance(id) {
        return await Http.put(`/api/advances/validate?id=${id}`);
    }
    static async editAdvance(id, payload) {
        return await Http.put(`/api/advances/edit?id=${id}`, payload);
    }
    static async getSingleAdvances(id) {
        return await Http.get(`/api/advances/single-advance?id=${id}`);
    }
    // static async updateEmployee(id, payload) {
    //     return await Http.put(`/api/employees/update?id=${id}`, payload);
    // }
    // static async getSingleEmploye(id) {
    //     return await Http.get(`/api/employees/single?id=${id}`);
    // }
    // static async addDocument(payload, file) {
    //     const formdata = new FormData();
    //     formdata.append('body', JSON.stringify(payload));
    //     formdata.append('file', file);
    //     return await Http.post(`/api/employees/add-document`, formdata);
    // }
    static async deleteAdvance(id) {
        return await Http.delete(`/api/advances/delete?id=${id}`);
    }
}

export default AdvancesService
