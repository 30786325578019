import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const styles = {
    infoMatricule: {
        marginTop: '10px',
        '& p': {
            margin: 0,
            maxWidth: '310px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }
    }
}
const useStyles = makeStyles(styles);

function Autocompletes(props) {
    const classes = useStyles();
    const { onTagsChange, options, lastName, editMat } = props;
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (editMat !== null) {
            setIsEdit(true);
        }
    }, [editMat]);

    return (
        <div style={{ marginTop: '10px' }}>
            <Autocomplete
                disabled={isEdit}
                options={options}
                getOptionLabel={(option) => option.matricule}
                onChange={onTagsChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={editMat !== null ? ("N° Matricule : ") : "N° Matricule"}
                        // label={editMat !== null ? ("N° Matricule : " + editMat) : "N° Matricule"}
                        variant="outlined"
                    />
                )}
            />
            <div className={classes.infoMatricule} >
                {lastName && (<p><strong>Nom :</strong> {lastName}</p>)}
            </div>
        </div>
    )
}

export default Autocompletes