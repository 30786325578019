import React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr';

export default function DatePickerInput(props) {
    const { name, label, value, onChange, view, formatPes } = props

    const convertToDefEventPara = (name, value) => ({
        target: {
            name,
            value
        }
    })

    return (
        <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    sx={{ width: '100%' }}
                    views={view || ['day', 'month', 'year']} 
                    label={label}
                    value={dayjs(value)}
                    format={formatPes || "DD/MM/YYYY"}
                    name={name}
                    onChange={(date) => onChange(convertToDefEventPara(name, date))}
                />
            </DemoContainer>
        </LocalizationProvider>
    )
}
