import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

const AuthSlice = createSlice({
    name: 'auth',
    initialState: { user: null, role: null, token: null, permissions: null },
    reducers: {
        setToken: (state, action) => {
            const { token } = action.payload
            const decodedToken = jwt_decode(token)

            state.token = token
            state.user = decodedToken.user
            state.role = decodedToken.role
            state.permissions = decodedToken.permissions
            //state.token = sessionStorage.getItem('token')
        },
        logOut: (state, action) => {
            state.token = null
            state.user = null
            state.role = null
            state.permissions = null
        }
    }
})

export const { setToken, logOut } = AuthSlice.actions

export default AuthSlice.reducer
