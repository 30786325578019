import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import ActionButton from "./ActionButton";
import Textarea from "./Textarea";
import InputPassword from "./InputPassword";
import InputTime from "./InputTime";
import AutoCompleteSelect from './AutoCompleteSelect';

const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    DatePicker,
    Button,
    ActionButton,
    Textarea,
    InputPassword,
    InputTime,
    AutoCompleteSelect
}

export default Controls;