import { makeStyles } from '@material-ui/core/styles';

const styles = {
  login_content: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& form': {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
      width: '300px',
      height: '160px',
      display: 'flex',
      padding: '40px 20px 20px',
      flexDirection: 'column',
      border: '1px solid rgba(0,0,0,0.12)',
      borderRadius: '5px',
      justifyContent: 'space-between',
    }
  }
}

const useStyles = makeStyles(styles);

export default useStyles;