import { Http } from '../../../applications/Http.js'

// class EmployeeService extends Service {
class EmployeeService {
    // static messages = {
    //     update_success: 'Employee updated.',
    //     update_error: 'Employee not updated.',
    //     delete_success: 'Employee deleted.',
    //     delete_error: 'Employee not deleted.',
    //     create_success: 'Employee created.',
    //     create_error: 'Employee not created.'
    // }

    static async getAllEmployee() {
        return await Http.get('/api/employees/list');
    }
    static async deleteEmployee(id) {
        return await Http.delete(`/api/employees/delete?id=${id}`);
    }
    static async addEmployee(payload, file) {
        const formdata = new FormData();
        formdata.append('body', JSON.stringify(payload));
        formdata.append('file', file);
        return await Http.put(`/api/employees/add`, formdata);
    }
    static async updateEmployee(id, payload, file) {
        const formdata = new FormData();
        formdata.append('body', JSON.stringify(payload));
        formdata.append('file', file);
        return await Http.put(`/api/employees/update?id=${id}`, formdata);
    }
    static async updateStatus(idEmployee, valuesStatus) {
        return await Http.put(`/api/employees/update-status?id=${idEmployee}`, {status: valuesStatus});
    }
    static async getSingleEmploye(id) {
        return await Http.get(`/api/employees/single?id=${id}`);
    }
    static async addDocument(payload, file) {
        const formdata = new FormData();
        formdata.append('body', JSON.stringify(payload));
        formdata.append('file', file);
        return await Http.post(`/api/employees/add-document`, formdata);
    }
    static async deleteDocument(id) {
        return await Http.delete(`/api/employees/delete-document?id=${id}`);
    }
    static async printPdfContract(employeeId) {
        return await Http.get(`/api/employees/print-pdf-contract?employeeId=${employeeId}`);
    }
    static async printPdfBadge(employeeId) {
        return await Http.get(`/api/employees/print-pdf-badge?employeeId=${employeeId}`);
    }
    static async generateExcel() {
        return await Http.get(`/api/employees/export-excel-employes`);
    }
    static async printPdfFDR(employeeId) {
        return await Http.get(`/api/employees/print-pdf-fdr?employeeId=${employeeId}`);
    }
}

export default EmployeeService
