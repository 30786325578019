import React, { useState } from 'react'
import ImportDataService from './service/ImportData.jsx'
// import { useAuth } from '../../applications/hooks/UseAuth';
import Notification from '../../components/notificationComponent/index.jsx'
import dataModels from './models.jsx'
import Controls from '../../components/Entry/Controls'
import { Button } from '@mui/material'
import { RiFileExcel2Line } from 'react-icons/ri'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { makeStyles } from '@material-ui/core/styles';

// const CURRENT_PAGE = 'importData';
const styles = {
    listItemText: {
        width: 'calc(100% - 90px)',
        overflowX: 'auto',
        wordWrap: 'break-word',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            width: '1px',
            height: '5px'
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888', 
            borderRadius: '10px',
        }
    }
}
const useStyles = makeStyles(styles);

function ImportData() {
    const models = dataModels.employeModel()
    const classes = useStyles();
    // const { haveAccessTo } = useAuth();
    // haveAccessTo(CURRENT_PAGE, 'add');
    const [openNotif, setOpenNotif] = useState(false)
    const [severityPerso, setSeverityPerso] = useState('')
    const [messageNotif, setMessageNotif] = useState('')
    const [selectedModel, setSelectedModel] = useState(null)
    const [dataSave, setDataSave] = useState([])
    const [activeSave, setActiveSave] = useState(false)
    const [errorList, setErrorList] = useState([])

    const handleCloseNotif = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setMessageNotif('')
        setOpenNotif(false)
        setSeverityPerso('')
    }

    const handleModelChange = (e) => {
        setSelectedModel(models.find((item) => item.tableName === e.target.value))
        setActiveSave(false);
        setErrorList([]);
    }
    
    const onChangeFile = async (e) => {
        if (e.target.files[0] !== undefined) {
            var response;
            // eslint-disable-next-line
            switch (selectedModel.tableName) {
                case 'employe':
                response = await ImportDataService.verifyDataEmploye(e.target.files[0]);
                break;
                case 'conge':
                response = await ImportDataService.verifyDataConge(e.target.files[0]);
                break;
                case 'abs':
                response = await ImportDataService.verifyDataAbs(e.target.files[0]);
                break;
                case 'pointage':
                response = await ImportDataService.verifyDataPointage(e.target.files[0]);
                break;
            }
            if(response.status.code === 200) {
                setActiveSave(true);
                setDataSave(response.data);
            } else {
                setErrorList(response.data);
                setActiveSave(false);
            }
        }
    }

    const saveDataArray = async () => {
        if (activeSave) {
            var response;
            // eslint-disable-next-line
            switch (selectedModel.tableName) {
                case 'employe':
                response = await ImportDataService.saveEmploye(dataSave);
                break;
                case 'conge':
                response = await ImportDataService.saveConge(dataSave);
                break;
                case 'abs':
                response = await ImportDataService.saveAbs(dataSave);
                break;
                case 'pointage':
                response = await ImportDataService.savePointage(dataSave);
                break;
            }
            if(response.status.code === 200) {
                setMessageNotif(response.status.message);
                setOpenNotif(true);
                setSeverityPerso('success');
                setActiveSave(false);
            } else {
                setMessageNotif(response.status.message);
                setOpenNotif(true);
                setSeverityPerso('error');
                setActiveSave(false);
            }
        }
    }

    return (
        <>
            <Notification
                severity={severityPerso}
                openNotif={openNotif}
                handleClose={handleCloseNotif}
                message={messageNotif}
            />
            <div style={{ padding: 20 }}>
                <Controls.AutoCompleteSelect
                    label="Modèle de données"
                    name="model"
                    options={models || []}
                    labelKey="label"
                    valueKey="tableName"
                    onChange={handleModelChange}
                    value={selectedModel?.tableName}
                />
            {selectedModel && (
                <>
                    <h4 style={{ margin: 0 }}>Format xlsx</h4>
                    <div style={{ width: '100%', overflowX: 'auto' }}>
                        <table>
                            <thead>
                                <tr>
                                    {selectedModel?.properties.map((item) => (
                                        <th style={{ fontSize: '12px', border: '1px solid' }} key={item.id}>
                                            {item.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <br />
                    <Button component="label">
                        <RiFileExcel2Line style={{ marginRight: '5px' }} />
                        Importer (.XLSX)
                        <input
                            hidden
                            type="file"
                            accept=".xlsx,.xls"
                            onChange={(e) =>
                                onChangeFile(e)
                            }
                        />
                    </Button>
                    {/* <Controls.FileInput
                        onClick={handleFileVerify}
                        onChange={onChangeFile}
                        label={'Vérifier'}
                        type="xlsx"
                        processCompleted={scanCompleted}
                    /> */}
                </>
            )}
            {errorList.length !== 0 &&
                <List sx={{ width: '100%' }}>
                    {errorList.map((item, index) => {
                        return  item.ligne &&
                                <ListItem key={index}>
                                    <ListItemText style={{ maxWidth: '90px' }} primary={`Ligne ${item.ligne}:`} />&nbsp;
                                        <span className={classes.listItemText}>
                                            {selectedModel.properties.map((el) =>
                                                (item[el.id] !== undefined ? item[el.id] + ", " : "")
                                            )}
                                        </span>
                                </ListItem>
                    })}
                </List>
            }
            { activeSave &&
                <div style={{ padding: 20 }}>
                    <Button onClick={() => saveDataArray()} component="label">
                        Savegarder
                    </Button>
                </div>
            }
            </div>
        </>
    )
}

export default ImportData
