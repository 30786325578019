import React, { useContext } from 'react';
import { Button, Grid } from '@mui/material';
import Controls from '../../../components/Entry/Controls';
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { AddEmployeeContext } from './index.jsx';
import FormControl from '@material-ui/core/FormControl';

const AddChildrenForm = (props) => {
    const { values, setValues, index, handleDeleteChildren } = props

    const handleInputChange = (e, index) => {
        const { name, value } = e.target
        values[index][name] = value
        setValues([...values])
    }

    const childGenreItems = [
        { id: 'garcon', title: 'Garçon' },
        { id: 'fille', title: 'Fille' }
    ]

    return (
        <Grid sx={{ marginBottom: '20px' }} container>
            <Grid item xs={3.5}>
                <FormControl style={{ width: '90%' }}>
                    <Controls.Input
                        name="childrenName"
                        label="Nom/Prénom"
                        value={values[index].childrenName}
                        onChange={(e) => {
                            handleInputChange(e, index)
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3.5}>
                <FormControl style={{ width: '90%' , marginTop: '-8px' }}>
                    <Controls.DatePicker
                        name="childrenDob"
                        label="Date de naissance"
                        value={values[index].childrenDob}
                        onChange={(e) => {
                            handleInputChange(e, index)
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3.5}>
                <FormControl style={{ width: '90%' }}>
                    <Controls.Select
                        name="childrenSexe"
                        label="Genre"
                        value={values[index].childrenSexe}
                        onChange={(e) => {
                            handleInputChange(e, index)
                        }}
                        options={childGenreItems}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={1.5}>
                <FormControl style={{ width: '90%'}}>
                    <Button onClick={() => handleDeleteChildren(index)} color="error">
                        <RemoveCircleOutlineOutlinedIcon /> Supprimer
                    </Button>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default function Children(props) {
    const { valuesChild, setValuesChild } = useContext(AddEmployeeContext)

    const handleAddChildren = (e) => {
        const oldValues = [...valuesChild]
        const key = oldValues.length
        oldValues.push({ key: key, childrenName: '', childrenDob: null, childrenSexe: '' })
        setValuesChild(oldValues)
    }

    const handleDeleteChildren = (key) => {
        const oldValues = [...valuesChild]
        const updateValues = oldValues.filter((item) => item.key !== key)
        setValuesChild(updateValues)
    }

    //handle load
    // useEffect(() => {
    //     !addEmployeeData.children
    //         ? setAddEmployeeData({
    //               ...addEmployeeData,
    //               children: values
    //           })
    //         : setValues(addEmployeeData.children)
    // }, [])

    //handle click to next
    // useEffect(() => {
    //     setAddEmployeeData({
    //         ...addEmployeeData,
    //         children: values.filter((item) => item.childrenName !== '')
    //     })
    // }, [clickBack, clickNext, values])

    return (
        <form>
            <Grid container>
                <Grid container style={{marginBottom: 10}}>
                    <Grid item xs={3.5}>
                        <Button onClick={handleAddChildren}>
                            {/* <AddOutlinedIcon /> */}
                            Ajouter un enfant
                        </Button>
                    </Grid>
                </Grid>
                {valuesChild.map((child, index) => {
                    console.log(index, child)
                    return (
                        <AddChildrenForm
                            key={index}
                            index={index}
                            values={valuesChild}
                            setValues={setValuesChild}
                            handleDeleteChildren={(e) => handleDeleteChildren(e, index)}
                        />
                    )
                })}
            </Grid>
        </form>
    )
}
