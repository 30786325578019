import React, { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// import { setPageTitle } from '../applications/reducers/HeaderSlice';
// import FaceFrownIcon  from '@heroicons/react/24/solid/FaceFrownIcon'
import { useNavigate } from 'react-router-dom';

export default function InternalPage () {

    // const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        navigate('/app/dashboard');
        // dispatch(setPageTitle({ title : ""}))
        // eslint-disable-next-line
      }, [])
    
    //   const goLogin = () => {
    //     navigate('/app/dashboard');
    //   }

    return(
        <div className="hero h-4/5 bg-base-200" style={{margin: 'auto'}}>
            <div className="hero-content text-accent text-center">
                <div className="max-w-md">
                {/* <FaceFrownIcon className="h-48 w-48 inline-block"/> */}
                {/* <h1 className="text-5xl  font-bold">404 - Not Found</h1> */}
                </div>
            </div>
        </div>
    )
}