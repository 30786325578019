import React, { useEffect, useState } from 'react';
import PayrollService from './service/Payroll.jsx';
import Button from '@mui/material/Button';
import Controls from '../../components/Entry/Controls.jsx';
import Table from '../../components/table/index.jsx';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { FaFileInvoiceDollar, FaRegFilePdf, FaRegFileExcel } from 'react-icons/fa';
import EmployeePayslip from './Payslip/index.jsx';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { useAuth } from '../../applications/hooks/UseAuth';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
// import { FaRegHdd } from "react-icons/fa";
import { FaRegSave } from "react-icons/fa";
import Notification from '../../components/notificationComponent/index.jsx';
import ModalComponent from '../../components/modalComponent/index.jsx';
import { Box, Typography } from '@material-ui/core';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const CURRENT_PAGE = 'payroll';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStylesFile = makeStyles(() => ({
    arrow: { color: 'blue', },
    tooltip: { backgroundColor: 'blue', },
  }));

function Tooltips(props) {
  const classes = useStylesFile();
  return <Tooltip arrow classes={classes} {...props} />;
}

const columnGroupingModel = [
  {
    groupId: 'salarialCharge',
    headerName: 'Charges Salariales',
    description: '',
    headerAlign: 'center',
    children: [
      { field: 'CNAPS' },
      { field: 'OSTIE' }
    ]
  },
  {
    groupId: 'patronalCharge',
    headerName: 'Charges Patronales',
    description: '',
    headerAlign: 'center',
    children: [
      { field: 'CNAPS2' },
      { field: 'OSTIE2' }
    ]
  },
];

function Payroll() {
    const { haveAccessTo } = useAuth();
    const [searchDate, setSearchDate] = useState(new Date());
    const [searchDateClose, setSearchDateClose] = useState(new Date());
    const [rows, setRows] = useState([]);
    const [rowsClose, setRowsClose] = useState([]);
    const [total, setTotal] = useState({
      ostieSalarial: 0,
      cnapsSalarial: 0,
      ostiePatronal: 0,
      cnapsPatronal: 0,
      baseImposable: 0,
      irsaNet: 0,
      NetSalary: 0,
    })
    const [totalClose, setTotalClose] = useState({
      ostieSalarial: 0,
      cnapsSalarial: 0,
      ostiePatronal: 0,
      cnapsPatronal: 0,
      baseImposable: 0,
      irsaNet: 0,
      NetSalary: 0,
    })
    const [showPayslip, setShowPayslip] = useState(false);
    const [idEmployee, setIdEmployee] = useState(null);
    const [loadingPrintPdf, setLoadingPrintPdf] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [loadingClose, setLoadingClose] = useState(false);
    const [severityPerso, setSeverityPerso] = useState("");
    const [openNotif, setOpenNotif] = useState(false);
    const [messageNotif, setMessageNotif] = useState('');
    const [openConfirmeClose, setOpenConfirmeClose] = useState(false);
    const [tab, setTab] = useState(0);
    
    const [chargePercentage, setchargePercentage] = useState({});
    const [chargePercentageClose, setchargePercentageClose] = useState({});

    useEffect(() => {
        async function getData() {
          const responseAll = await PayrollService.getAllPayroll(searchDate);
          let newRows = [];
          let sumOstie = 0;
          let sumCnaps = 0;
          let sumOstiePatronal = 0;
          let sumCnapsPatronal = 0;
          let sumBaseImposable = 0;
          let sumIrsaNet = 0;
          let sumNetSalary = 0;
          let percenCharges = {};
          if (responseAll.status.code === 200) {
              newRows = responseAll.data.map((e) => {
                  let newRow = {
                      id: e.data.employee.id,
                      matricule: e.data.employee.matricule,
                      lastName: e.data.employee.lastName,
                      firstName: e.data.employee.firstName,
                      department: e.data.employee.EmployeeContract[0].department,
                      baseSalary: e.data.employee.EmployeeContract[0].baseSalary.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                  };
                  e.data.payslip.forEach((item) => {
                      if (item?.label?.trim() === 'gainImposable') {
                        item?.list?.length > 0 &&
                          item?.list.forEach((el) => {
                            if (
                              el.variableName === 'Indemnité' ||
                              el.variableName === 'Prime'
                            ) {
                              newRow[el.variableName] = (el?.amount || "");
                            }
                          })
                      }
                      if (
                          (
                          item?.label?.trim() === '- IRSA NET' ||
                          item?.label?.trim() === 'IRSA BRUT' ||
                          item?.label?.trim() === 'Salaire BRUT' ||
                          item?.label?.trim() === 'Salaire NET') && item?.showInPaie
                        ) {
                          newRow[item?.label?.split(' ')?.join('_')] = (item?.value || 0);
                        }
                        if ((item?.label?.trim() === 'OSTIE' && !item?.chargesPatronales) && item?.showInPaie) {
                          percenCharges["ostieSalarial"] = item.percenValue
                          newRow["OSTIE"] = (item?.value || 0);
                        }
                        if ((item?.label?.trim() === 'CNAPS' && !item?.chargesPatronales) && item?.showInPaie) {
                          percenCharges["cnapsSalarial"] = item.percenValue
                          newRow["CNAPS"] = (item?.value || 0);
                        }
                        if ((item?.label?.trim() === 'OSTIE' && item?.chargesPatronales) && item?.showInPaie) {
                          percenCharges["ostiePatronal"] = item.percenValue
                          newRow["OSTIE2"] = (item?.value || 0);
                        }
                        if ((item?.label?.trim() === 'CNAPS' && item?.chargesPatronales) && item?.showInPaie) {
                          percenCharges["cnapsPatronal"] = item.percenValue
                          newRow["CNAPS2"] = (item?.value || 0);
                        }
                        if (item?.label?.trim() === 'OSTIE' && item?.showInPaie  && !item?.chargesPatronales) {
                          sumOstie += item?.intValue;
                        }
                        if (item?.label?.trim() === 'CNAPS' && item?.showInPaie  && !item?.chargesPatronales) {
                          sumCnaps += item?.intValue;
                        }
                        if (item?.label?.trim() === 'OSTIE' && item?.chargesPatronales) {
                          sumOstiePatronal += item?.intValue;
                        }
                        if (item?.label?.trim() === 'CNAPS' && item?.chargesPatronales) {
                          sumCnapsPatronal += item?.intValue;
                        }
                        if (item?.label?.trim() === 'Base imposable' && item?.showInPaie) {
                          sumBaseImposable += item?.intValue;
                        }
                        if (item?.label?.trim() === '- IRSA NET' && item?.showInPaie) {
                          sumIrsaNet += item?.intValue;
                        }
                        if (item?.label?.trim() === 'Salaire NET' && item?.showInPaie) {
                          sumNetSalary += item?.intValue;
                        }
                  });
                  return newRow;
              });
          }
          setchargePercentage(percenCharges);
          setRows(newRows);
          setTotal({
            ...total,
            ostieSalarial: sumOstie.toFixed(2),
            cnapsSalarial: sumCnaps.toFixed(2),
            ostiePatronal: sumOstiePatronal.toFixed(2),
            cnapsPatronal: sumCnapsPatronal.toFixed(2),
            baseImposable: sumBaseImposable.toFixed(2),
            irsaNet: sumIrsaNet.toFixed(2),
            NetSalary: sumNetSalary.toFixed(2)
          })
        }
        getData();
        // eslint-disable-next-line
    }, [searchDate]);

    useEffect(() => {
      async function getDataPayrollClose() {
        const responseAllClose = await PayrollService.getAllPayrollClose(searchDateClose);
        let newRows = [];
        let sumOstie = 0;
        let sumCnaps = 0;
        let sumOstiePatronal = 0;
        let sumCnapsPatronal = 0;
        let sumBaseImposable = 0;
        let sumIrsaNet = 0;
        let sumNetSalary = 0;
        let percenCharges = {};
        if (responseAllClose.status.code === 200) {
          const dataArray = JSON.parse(responseAllClose.data.payroll);
          newRows = dataArray.map((e) => {
              let newRow = {
                  id: e.employee.id,
                  matricule: e.employee.matricule,
                  lastName: e.employee.lastName,
                  firstName: e.employee.firstName,
                  department: e.employee.EmployeeContract[0].department,
                  baseSalary: e.employee.EmployeeContract[0].baseSalary.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
              };
              e.payslip.forEach((item) => {
                  if (item?.label?.trim() === 'gainImposable') {
                    item?.list?.length > 0 &&
                      item?.list.forEach((el) => {
                        if (
                          el.variableName === 'Indemnité' ||
                          el.variableName === 'Prime'
                        ) {
                          newRow[el.variableName] = (el?.amount || "");
                        }
                      })
                  }
                  if (
                      (
                      item?.label?.trim() === '- IRSA NET' ||
                      item?.label?.trim() === 'IRSA BRUT' ||
                      item?.label?.trim() === 'Salaire BRUT' ||
                      item?.label?.trim() === 'Salaire NET') && item?.showInPaie
                    ) {
                      newRow[item?.label?.split(' ')?.join('_')] = (item?.value || 0);
                    }
                    if ((item?.label?.trim() === 'OSTIE' && !item?.chargesPatronales) && item?.showInPaie) {
                      percenCharges["ostieSalarial"] = item.percenValue
                      newRow["OSTIE"] = (item?.value || 0);
                    }
                    if ((item?.label?.trim() === 'CNAPS' && !item?.chargesPatronales) && item?.showInPaie) {
                      percenCharges["cnapsSalarial"] = item.percenValue
                      newRow["CNAPS"] = (item?.value || 0);
                    }
                    if ((item?.label?.trim() === 'OSTIE' && item?.chargesPatronales) && item?.showInPaie) {
                      percenCharges["ostiePatronal"] = item.percenValue
                      newRow["OSTIE2"] = (item?.value || 0);
                    }
                    if ((item?.label?.trim() === 'CNAPS' && item?.chargesPatronales) && item?.showInPaie) {
                      percenCharges["cnapsPatronal"] = item.percenValue
                      newRow["CNAPS2"] = (item?.value || 0);
                    }
                    if (item?.label?.trim() === 'OSTIE' && item?.showInPaie && !item?.chargesPatronales) {
                      sumOstie += item?.intValue;
                    }
                    if (item?.label?.trim() === 'CNAPS' && item?.showInPaie && !item?.chargesPatronales) {
                      sumCnaps += item?.intValue;
                    }
                    if (item?.label?.trim() === 'OSTIE' && item?.chargesPatronales) {
                      sumOstiePatronal += item?.intValue;
                    }
                    if (item?.label?.trim() === 'CNAPS' && item?.chargesPatronales) {
                      sumCnapsPatronal += item?.intValue;
                    }
                    if (item?.label?.trim() === 'Base imposable' && item?.showInPaie) {
                      sumBaseImposable += item?.intValue;
                    }
                    if (item?.label?.trim() === '- IRSA NET' && item?.showInPaie) {
                      sumIrsaNet += item?.intValue;
                    }
                    if (item?.label?.trim() === 'Salaire NET' && item?.showInPaie) {
                      sumNetSalary += item?.intValue;
                    }
              });
              return newRow;
          });
          setTotalClose({
            ...totalClose,
            ostieSalarial: sumOstie.toFixed(2),
            cnapsSalarial: sumCnaps.toFixed(2),
            ostiePatronal: sumOstiePatronal.toFixed(2),
            cnapsPatronal: sumCnapsPatronal.toFixed(2),
            baseImposable: sumBaseImposable.toFixed(2),
            irsaNet: sumIrsaNet.toFixed(2),
            NetSalary: sumNetSalary.toFixed(2)
          })
        } else {
          setRowsClose([]);
          setTotalClose({
            ostieSalarial: 0,
            cnapsSalarial: 0,
            ostiePatronal: 0,
            cnapsPatronal: 0,
            baseImposable: 0,
            irsaNet: 0,
            NetSalary: 0
          })
        }
        setchargePercentageClose(percenCharges);
        setRowsClose(newRows);
      }
      getDataPayrollClose();
      // eslint-disable-next-line
    }, [searchDateClose, tab]);

    const columns = [
        {
          field: 'matricule',
          headerName: 'Mat.',
          align: 'center',
          headerAlign: 'center',
          flex: 1
        },
        {
          field: 'lastName',
          headerName: 'Nom',
          align: 'center',
          headerAlign: 'center',
          flex: 1
        },
        {
          field: 'firstName',
          headerName: 'Prénom',
          align: 'center',
          headerAlign: 'center',
          flex: 1
        },
        {
          field: 'department',
          headerName: 'Département',
          align: 'center',
          headerAlign: 'center',
          flex: 1
        },
        {
          field: 'baseSalary',
          headerName: 'Salaire de base',
          align: 'center',
          headerAlign: 'center',
          flex: 1
        },
        {
          field: 'Indemnité',
          headerName: 'Indemnité',
          align: 'center',
          headerAlign: 'center',
          flex: 1
        },
        {
          field: 'Prime',
          headerName: 'Prime',
          align: 'center',
          headerAlign: 'center',
          flex: 1
        },
        {
          field: 'Salaire_BRUT',
          headerName: 'Salaire Brut',
          align: 'center',
          headerAlign: 'center',
          flex: 1
        },
        {
          field: 'CNAPS',
          headerName: `CNAPS ${tab === 0 ? chargePercentage.cnapsSalarial : (chargePercentageClose.cnapsSalarial || "")}`,
          align: 'center',
          headerAlign: 'center',
          flex: 1
        },
        {
          field: 'OSTIE',
          headerName: `OSTIE ${tab === 0 ? chargePercentage.ostieSalarial : (chargePercentageClose.ostieSalarial || "")}`,
          align: 'center',
          headerAlign: 'center',
          flex: 1
        },
        {
          field: 'CNAPS2',
          headerName: `CNAPS ${tab === 0 ? chargePercentage.cnapsPatronal : (chargePercentageClose.cnapsPatronal || "")}`,
          align: 'center',
          headerAlign: 'center',
          flex: 1
        },
        {
          field: 'OSTIE2',
          headerName: `OSTIE ${tab === 0 ? chargePercentage.ostiePatronal : (chargePercentageClose.ostiePatronal || "")}`,
          align: 'center',
          headerAlign: 'center',
          flex: 1
        },
        {
          field: 'IRSA_BRUT',
          headerName: 'IRSA BRUT',
          align: 'center',
          headerAlign: 'center',
          flex: 1
        },
        {
          field: '-_IRSA_NET',
          headerName: '- IRSA NET',
          align: 'center',
          headerAlign: 'center',
          flex: 1
        },
        {
          field: 'Salaire_NET',
          headerName: 'Salaire NET',
          align: 'center',
          headerAlign: 'center',
          flex: 1
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 130,
            cellClassName: 'actions',
            align: 'center',
            headerAlign: 'center',
            getActions: (params) => {
                const displayPayslip = (params) => () => {
                    setShowPayslip(true);
                    setIdEmployee(params.id);
                  }
              return [
                <>
                  <Tooltips TransitionComponent={Zoom} title="Fiche de paie">
                    <Button
                      className="event-file-btn contentBtnStyle forAllBtn"
                      disabled={!haveAccessTo(CURRENT_PAGE, 'paieEmployee')}
                      onClick={displayPayslip(params)}
                    >
                      <FaFileInvoiceDollar className='actionIconStyle' />
                    </Button>
                  </Tooltips>
                </>
              ];
            },
          },
      ];
    
    const handleCloseNotif = (event, reason) => {
      if (reason === 'clickaway') {
          return;
      }
      setMessageNotif("");
      setOpenNotif(false);
      setSeverityPerso("");
    };

    const downloadAllPDF = () => {
      setLoadingPrintPdf(true);
      PayrollService.generateAllPdfPaySlip(searchDate).then(
          (res) => {
              const pdf64 = res.data.content;
              let a = document.createElement("a");
              a.href = "data:application/octet-stream;base64,"+pdf64;
              a.download = `bulletin-de-paie-${moment(res.data.period).format("MMMM-YYYY")}.pdf`
              a.click();
              setLoadingPrintPdf(false);
          },
          () => {
              setLoadingPrintPdf(false);
          }
      )
    }

    const downloadAllPDFClose = () => {
      setLoadingPrintPdf(true);
      PayrollService.generateAllPdfPaySlipClose(searchDate).then(
          (res) => {
              const pdf64 = res.data.content;
              let a = document.createElement("a");
              a.href = "data:application/octet-stream;base64,"+pdf64;
              a.download = `bulletin-de-paie-${moment(res.data.period).format("MMMM-YYYY")}.pdf`
              a.click();
              setLoadingPrintPdf(false);
          },
          () => {
              setLoadingPrintPdf(false);
          }
      )
    }

    const downloadAllExcel = () => {
      setLoadingExcel(true);
      PayrollService.generateAllExcelPaySlip(searchDate).then(
          (res) => {
              let a = document.createElement('a');
              a.href = 'data:application/octet-stream;base64,' + res.data.content;
              a.download = `Etat-de-paie-${moment(res.data.period).format("MMMM-YYYY")}.xlsx`;
              a.click();

              setLoadingExcel(false);
          },
          () => {
              setLoadingExcel(false);
          }
      )
    }

    const downloadAllExcelClose = () => {
      setLoadingExcel(true);
      PayrollService.generateAllExcelPaySlipClose(searchDateClose).then(
          (res) => {
              let a = document.createElement('a');
              a.href = 'data:application/octet-stream;base64,' + res.data.content;
              a.download = `Etat-de-paie-${moment(res.data.period).format("MMMM-YYYY")}.xlsx`;
              a.click();

              setLoadingExcel(false);
          },
          () => {
              setLoadingExcel(false);
          }
      )
    }

    const handleConfirmeClose = () => {
      setOpenConfirmeClose(true);
    }

    const closeMonth = () => {
      setOpenConfirmeClose(false);
      setLoadingClose(true);
      PayrollService.closePayslipMonth(searchDate).then(
        (res) => {
          if(res.status.code === 200) {
            setSeverityPerso("success");
            setOpenNotif(true);
            setMessageNotif(res.status.message);
            setLoadingClose(false);
          } else {
            setSeverityPerso("error");
            setOpenNotif(true);
            setMessageNotif(res.status.message);
            setLoadingClose(false);
          }
        }
      )
    }

    const handleChange = (event, newValue) => {
      setTab(newValue);
    };

    return (
        <>
            <Notification
                severity={severityPerso}
                openNotif={openNotif}
                handleClose={handleCloseNotif}
                message={messageNotif}
            />
            <ModalComponent
                openModal={openConfirmeClose}
                close={() => setOpenConfirmeClose(false)}
                title={'Confirmation'}
                submit={() => closeMonth()}
                actionTitle={'Oui'}
                modalContentBody={"Est-ce que vous voulez vraiment clôturer le paiement du mois de " + moment(searchDate).format("MMMM YYYY") + "?"}
            />
            <Tabs
              value={tab}
              onChange={handleChange}
              centered
              aria-label="icon position tabs"
            >
              <Tab
                className='iconSize'
                label="Livre de paie en cours"
                {...a11yProps(0)}
              />
              <Tab
                className='iconSize'
                label="Livre de paie clôturer"
                {...a11yProps(1)}
              />
            </Tabs>
            {idEmployee !== null &&
            <EmployeePayslip
                searchDate={tab === 0 ? searchDate : searchDateClose}
                tab={tab}
                idEmployee={idEmployee}
                open={showPayslip}
                onClose={() => {
                    setShowPayslip(false);
                    setIdEmployee(null);
                }}
            />}
            <TabPanel value={tab} index={0}>
              <div style={{ padding: 20, marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  {/* <h4 style={{ margin: 0 }}>Livre de paie</h4> */}
                  <Controls.DatePicker
                      name="search"
                      label="Période"
                      view={['month', 'year']}
                      value={searchDate}
                      formatPes={"MMMM YYYY"}
                      onChange={(e) => setSearchDate(e.target.value)}
                  />
                  <div>
                    <Tooltips TransitionComponent={Zoom} title="Export excel">
                      <Button style={{ minWidth: 'auto' }} disabled={loadingExcel} onClick={downloadAllExcel}>
                        { 
                            loadingExcel &&
                            <CircularProgress style={{ width: '30px', height: '30px', position: 'absolute' }} color="inherit" />
                        }
                        <FaRegFileExcel style={{ width: '20px', height: '20px' }} />
                      </Button>
                    </Tooltips>
                    <Tooltips TransitionComponent={Zoom} title="Imprimer toutes">
                      <Button style={{ minWidth: 'auto' }} disabled={loadingPrintPdf} onClick={downloadAllPDF}>
                        { 
                          loadingPrintPdf &&
                            <CircularProgress style={{ width: '30px', height: '30px', position: 'absolute' }} color="inherit" />
                        }
                        <FaRegFilePdf style={{ width: '20px', height: '20px' }} />
                      </Button>
                    </Tooltips>
                    <Tooltips TransitionComponent={Zoom} title="Clôturer">
                      <Button style={{ minWidth: 'auto' }} disabled={loadingClose} onClick={handleConfirmeClose}>
                        { 
                            loadingClose &&
                            <CircularProgress style={{ width: '30px', height: '30px', position: 'absolute' }} color="inherit" />
                        }
                        <FaRegSave style={{ width: '20px', height: '20px' }} />
                      </Button>
                    </Tooltips>
                  </div>
              </div>
              <Table columns={columns} rows={rows} columnGroupingModel={columnGroupingModel}/>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <h4>Total</h4>
                  <ListItem sx={{ textAlign: 'center'}}>
                      <ListItemText primary="OSTIE PATRONALES" secondary={total.ostiePatronal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
                      <ListItemText primary="CNAPS PATRONALES" secondary={total.cnapsPatronal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
                  </ListItem>
                  <ListItem sx={{ textAlign: 'center'}}>
                      <ListItemText primary="OSTIE SALARIALES" secondary={total.ostieSalarial.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
                      <ListItemText primary="CNAPS SALARIALES" secondary={total.cnapsSalarial.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
                      <ListItemText primary="BASE IMPOSABLE" secondary={total.baseImposable.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
                      <ListItemText primary="IRSA NET" secondary={total.irsaNet.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
                      <ListItemText primary="SALAIRE NET" secondary={total.NetSalary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
                  </ListItem>
              </div>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Controls.DatePicker
                  name="search"
                  label="Période"
                  view={['month', 'year']}
                  value={searchDateClose}
                  formatPes={"MMMM YYYY"}
                  onChange={(e) => setSearchDateClose(e.target.value)}
                />
                <div>
                    <Tooltips TransitionComponent={Zoom} title="Export excel">
                      <Button style={{ minWidth: 'auto' }} disabled={loadingExcel} onClick={downloadAllExcelClose}>
                        { 
                            loadingExcel &&
                            <CircularProgress style={{ width: '30px', height: '30px', position: 'absolute' }} color="inherit" />
                        }
                        <FaRegFileExcel style={{ width: '20px', height: '20px' }} />
                      </Button>
                    </Tooltips>
                    <Tooltips TransitionComponent={Zoom} title="Imprimer toutes">
                      <Button style={{ minWidth: 'auto' }} disabled={loadingPrintPdf} onClick={downloadAllPDFClose}>
                        { 
                          loadingPrintPdf &&
                            <CircularProgress style={{ width: '30px', height: '30px', position: 'absolute' }} color="inherit" />
                        }
                        <FaRegFilePdf style={{ width: '20px', height: '20px' }} />
                      </Button>
                    </Tooltips>
                  </div>
              </div>
              <Table columns={columns} rows={rowsClose} columnGroupingModel={columnGroupingModel}/>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <h4>Total</h4>
                  <ListItem sx={{ textAlign: 'center'}}>
                      <ListItemText primary="OSTIE PATRONALES" secondary={totalClose.ostiePatronal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
                      <ListItemText primary="CNAPS PATRONALES" secondary={totalClose.cnapsPatronal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
                  </ListItem>
                  <ListItem sx={{ textAlign: 'center'}}>
                      <ListItemText primary="OSTIE SALARIALES" secondary={totalClose.ostieSalarial.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
                      <ListItemText primary="CNAPS SALARIALES" secondary={totalClose.cnapsSalarial.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
                      <ListItemText primary="BASE IMPOSABLE" secondary={totalClose.baseImposable.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
                      <ListItemText primary="IRSA NET" secondary={totalClose.irsaNet.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
                      <ListItemText primary="SALAIRE NET" secondary={totalClose.NetSalary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
                  </ListItem>
              </div>
            </TabPanel>
        </>
    )
}

export default Payroll;