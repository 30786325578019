import { Http } from '../../../applications/Http.js'
class RubriqueService {

    static async getAllRubrique() {
        return await Http.get('/api/rubrique/list');
    }
    static async addRubrique(payload) {
        return await Http.put(`/api/rubrique/add`, payload);
    }
    static async editRubrique(id, payload) {
        return await Http.put(`/api/rubrique/edit?id=${id}`, payload);
    }
    static async getSingleRubrique(id) {
        return await Http.get(`/api/rubrique/single-rubrique?id=${id}`);
    }
    static async deleteRubrique(id) {
        return await Http.delete(`/api/rubrique/delete?id=${id}`);
    }
}

export default RubriqueService
