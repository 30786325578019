
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
// import Zoom from '@material-ui/core/Zoom';

export default function Tooltips(props) {
  const useStyles = makeStyles(() => ({
    arrow: { color: 'blue', },
    tooltip: { backgroundColor: 'blue', },
  }));

  const classes = useStyles();
  return <Tooltip arrow classes={classes} {...props} />;
}