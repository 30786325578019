import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import { useEffect, useState } from "react";
import ErrorHandler from "../../applications/Error";
// import { useAuth } from "../../applications/hooks/UseAuth";
// import ToolbarButton from "../../components/DataTable/ToolbarButton";
import PageHeader from "../../components/Typography/PageHeader";
// import AddNewConfig from "./components/AddNewConfig";
import Test from "./components/GlobalConfig";
import ConfigEmailService from "./services/globalCongif";
import Button from '@mui/material/Button';

export default function Index() {
  const service = ConfigEmailService.load();
  const [globalData, setGlobalData] = useState(null);
  // const { haveAccessTo } = useAuth();
  // const [needLoad, setNeedLoad] = useState(true);
  // const useDrawer = false;

  // const CURRENT_PAGE = "administration_roles";

  async function valideEmailConfig() {
    try {
      const newConfig = globalData?.map((d) => {
        return {
          ...d,
          data: JSON.stringify(d.data),
        };
      });
      const res = await service.updateConfig(newConfig);
      if (res?.status.code === 200) {
        alert(service.messages.update_success);
      }
    } catch (err) {
      ErrorHandler.handle(err);
    }
  }
  useEffect(() => {
    const getAllConfigs = async () => {
      try {
        const configs = await service.getAllConfig();
        if (configs) {
          const newConfig = configs.data.map((d) => {
            return {
              ...d,
              data: JSON.parse(d.data),
            };
          });
          setGlobalData(newConfig);
        }
      } catch (e) {
        ErrorHandler.handle(e);
      }
    };
    getAllConfigs();
  }, [service]);

  return (
    <div className={"card w-full bg-base-100"} style={{ paddingBottom: 20 }}>
      <PageHeader
        title={"Configurations"}
        subTitle={"Gestion des configurations"}
        icon={<LockPersonOutlinedIcon color="primary" fontSize="large" />}
      >
        {" "}
        {/* <ToolbarButton
          moduleTitle="Configurations"
          service={service}
          drawer={useDrawer}
          haveAccessTo={haveAccessTo}
          currentPage={CURRENT_PAGE}
          setNeedLoad={setNeedLoad}
          needLoad={needLoad}
          Form={AddNewConfig}
        /> */}
      </PageHeader>
      <>
        <div className="flex flex-col gap-6">
          {globalData !== null &&
            globalData?.length > 0 &&
            globalData.map((d) => (
              <Test key={d.id} data={d} setGlobalData={setGlobalData} />
            ))}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Button style={{ marginRight: '10px' }} variant="text">Annuler</Button>
          <Button variant="text" onClick={valideEmailConfig}>
            Valider
          </Button>
        </div>
      </>
    </div>
  );
}
