import { Navigate, Outlet } from "react-router-dom"
import { useAuth } from '../applications/hooks/UseAuth'

const LoginGuard = () => {
    const { token } = useAuth(/* reloadToken */)
    return (
        token
            ? <Navigate to="app/dashboard" replace />
            : <Outlet />
    )
}
export default LoginGuard