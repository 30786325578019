import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
//import QRCodeGenerator from './bouton/QRCodeGenerator';
import moment from 'moment';
import { BsPersonExclamation } from 'react-icons/bs';
import { LuMapPin } from 'react-icons/lu';
import { LuBaby } from 'react-icons/lu';
import { LuPhone } from 'react-icons/lu';
import { IoCarSportOutline } from 'react-icons/io5';
import { CiImageOn } from 'react-icons/ci';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardBody from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
// import avatarMan from './icons/avatarMan.png';
// import {Download} from "@mui/icons-material";
import { Button } from '@mui/material';
import { CircularProgress } from '@material-ui/core';
import { Alert } from '@mui/material';
// import { Alert, Collapse, ClickAwayListener } from '@mui/material';
import avatar from '../../../assets/img/ava3.png';
import { BsPersonVcard, BsPersonBadgeFill } from "react-icons/bs";
import EmployeeService from '../services/Employee.jsx';
import Notification from '../../../components/notificationComponent/index.jsx';

export default function ProfileInfo(props) {
  const { employee } = props;
  const url = process.env.REACT_APP_BASE_URL;
  const [loadingBadge, setLoadingBadge] = useState(false);
  const [loadingFDR, setLoadingFDR] = useState(false);
  const [openNotif, setOpenNotif] = useState(false);
  const [messageNotif, setMessageNotif] = useState('');

  const downloadPDFBadge = () => {
    setLoadingBadge(true);
    EmployeeService.printPdfBadge(employee.id).then(
      (res) => {
        if(res.status.code === 200) {
          const pdf64 = res.data.content;
          let a = document.createElement("a");
          a.href = "data:application/octet-stream;base64,"+pdf64;
          a.download = `badge-${res.data.matricule}.pdf`
          a.click();
          setLoadingBadge(false);
        } else {
          setOpenNotif(true);
          setMessageNotif(res.status.message);
          setLoadingBadge(false);
        }
      },
    )
  }

  const downloadPDFFDR = () => {
    setLoadingFDR(true);
    EmployeeService.printPdfFDR(employee.id).then(
      (res) => {
        if(res.status.code === 200) {
          const pdf64 = res.data.content;
          let a = document.createElement("a");
          a.href = "data:application/octet-stream;base64,"+pdf64;
          a.download = `fiche-de-renseignement-${res.data.matricule}.pdf`
          a.click();
          setLoadingFDR(false);
        } else {
          setOpenNotif(true);
          setMessageNotif(res.status.message);
          setLoadingFDR(false);
        }
      },
    )
  }

  const handleCloseNotif = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setMessageNotif("");
    setOpenNotif(false);
  };

  return (
    <>
      <Notification
        severity="error"
        openNotif={openNotif}
        handleClose={handleCloseNotif}
        message={messageNotif}
      />
      {Object.keys(employee).length !== 0 &&
      <Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }} >
          <Box style={{ width: '59%' }}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe">
                    <BsPersonExclamation size={15} />
                  </Avatar>
                }
                title="ETAT CIVIL"
              />
              <CardBody style={{ paddingTop: '0' }}>
                <Box>
                  <Box>
                    <Box><u>Nom</u> : {employee.lastName || '-'}</Box>
                    <Box><u>Prénom</u> : {employee.firstName || '-'}</Box>
                  </Box>
                </Box>
                <br />

                {!moment(employee.dateOfBirth).isValid() ? (
                  <Alert severity="warning">
                    Veuillez renseigner la date de naissance de cette personne.
                  </Alert>
                ) : (
                  <>
                    <Box>
                      <u>Date de naissance</u> :{' '}
                      {employee.dateOfBirth ? moment(employee.dateOfBirth).format(
                        'DD MMMM YYYY'
                      ) : '-'}{' '}
                      à {employee.placeOfBirth || '-'}
                    </Box>
                  </>
                )}
                <br />

                {!employee.nationality ? (
                  <Alert severity="warning">
                    Veuillez renseigner la nationalité de cette personne.
                  </Alert>
                ) : (
                  <>
                    <Box>
                      <Box>
                        <Box>
                          <u>Nationalité</u> : {employee.nationality === 'MG' ? 'Malagasy' : 'Expatrié' || '-'}
                        </Box>

                        {employee.nationality === 'MG' && (
                          <Box>
                            <u>CIN</u> : {employee.cin || '-'} délivré
                            le{' '}
                            {employee.cinDate ? moment(employee.cinDate).format(
                              'DD MMMM YYYY'
                            ) : '-'}{' '}
                            à {employee.cinPlace || '-'}
                          </Box>
                        )}

                        {employee.nationality !== 'MG' && (
                          <Box>
                            <Box>
                              <u>Passeport</u> :{' '}
                              {employee.passeport || '-'} délivré le{' '}
                              {employee.passeportDate ? moment(employee.passeportDate).format(
                                'DD MMMM YYYY'
                              ) : '-'}{' '}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </>
                )}

                {!employee.nationality ? (
                  <Alert severity="warning">
                    Veuillez renseigner la situation familiale de cette personne.
                  </Alert>
                ) : (
                  <>
                    <Box style={{ marginTop: '10px' }}>
                      <Box>
                        <Box>
                          <u>Situation familiale</u> :{' '}
                          {employee.maritalStatus !== "veuf" ? (employee.maritalStatus !== "divorce" ? (employee.maritalStatus === "celibataire" ? "Célibataire" : "Marié(e)") : "Divorcé(e)") : "veuf(ve)"}
                        </Box>
                        {employee.maritalStatus === 'marie' && (
                          <>
                            <Box><u> Epoux(se)</u> : {employee.conjointName || '-'} </Box>

                            <Box> <u> Né(e) le </u> : {employee.conjointDob ? moment(employee.conjointDob)
                              .format(
                                'DD MMMM YYYY'
                              ) : '-'}
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  </>
                )}
              </CardBody>
            </Card>
          </Box>
          <Box style={{ width: '39%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Card style={{ height: '100%'}}>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe">
                    <CiImageOn size={15} style={{ marginRight: '5px' }} />
                  </Avatar>
                }
                title="PHOTO"
              />
              <CardBody style={{ paddingTop: '0', margin : 'auto', width: '200px', height: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {employee.photo === null ? (
                  <img src={avatar} alt={avatar} style={{ maxWidth: '160px' }} />
                ) : (
                  <>
                    <Typography component='div' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Box>
                        <img src={url+employee.photo} alt={employee.photo} style={{ maxWidth: '160px' }} />
                      </Box>
                    </Typography>
                  </>
                )}
                <Box sx={{ flexDirection: 'row' }}>
                  <Button
                    disabled={loadingBadge}
                    onClick={downloadPDFBadge}
                    style={{ textTransform: 'capitalize' }}
                    variant="text"
                    size="small"
                  >{ loadingBadge && <CircularProgress style={{ width: '20px', height: '20px', position: 'absolute', left: '2px' }} color="inherit" />}
                    <BsPersonBadgeFill style={{ marginRight: '5px' }} />
                    Badge
                  </Button>
                  <Button
                    disabled={loadingFDR}
                    onClick={downloadPDFFDR}
                    style={{ textTransform: 'capitalize' }}
                    variant="text"
                    size="small"
                  >{ loadingFDR && <CircularProgress style={{ width: '20px', height: '20px', position: 'absolute', left: '2px' }} color="inherit" />}
                    <BsPersonVcard style={{ marginRight: '5px' }} />
                    Fiche de renseignement
                  </Button>
                </Box>
              </CardBody>
            </Card>
          </Box>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px', width: '49%'}} >
          <Card>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  <LuMapPin size={15} style={{ marginRight: '5px' }} />
                </Avatar>
              }
              title="ADRESSE"
            />
            <CardBody style={{ paddingTop: '0' }}>
              {employee.EmployeeAddress.length === 0 ? (
                <Alert severity="warning">
                  Pas d&apos;adresse enregistrée.
                </Alert>
              ) : (
                  <Typography component='div'>
                    {employee.EmployeeAddress[0].address || '-'}
                    {employee.EmployeeAddress[0].city &&
                      ' ' + employee.EmployeeAddress[0].city}
                    {employee.EmployeeAddress[0].postalCode && ' ' + employee.EmployeeAddress[0].postalCode}{', '}
                    {employee.EmployeeAddress[0].country &&
                      ' ' + employee.EmployeeAddress[0].country}
                  </Typography>
              )}
            </CardBody>
          </Card>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Card style={{ width: '100%', marginRight: '20px' }}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  <LuBaby size={15} style={{ marginRight: '5px' }} />
                </Avatar>
              }
              title="ENFANT(S)"
            />
            <CardBody style={{ paddingTop: '0' }}>
              <u>Nombre d&apos;enfants</u> : {employee?.EmployeeChildren?.length}
            </CardBody>
          </Card>

          <Card style={{ width: '92%', marginRight: '20px' }}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  <LuPhone size={15} style={{ marginRight: '5px' }} />
                </Avatar>
              }
              title="CONTACTS"
            />
            <CardBody style={{ paddingTop: '0' }}>
              {!employee.phoneNumber ? (
                <Alert severity="warning">Pas de téléphone enregistré</Alert>
              ) : (
                <>
                  <Box>
                    <Box><u> Téléphone</u> : {employee.phoneNumber || '-'}</Box>
                  </Box>
                </>
              )}
              <Box style={{ marginTop: '10px' }}>
                {!employee.urgencePhoneNumber ? (
                  <Alert severity="warning">
                    Pas de numéro d&apos;urgence enregistré
                  </Alert>
                ) : (
                  <>
                    <u>En cas d&apos;urgence</u> : <br />
                    <Box>
                      <Box>
                        <Box><u>Nom</u> : {employee.urgencePersonName || '-'}<br /></Box>
                        <Box><u>Téléphone</u> : {employee.urgencePhoneNumber || '-'}{' '}<br /></Box>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </CardBody>
          </Card>

          <Card style={{ width: '100%' }}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                  <IoCarSportOutline size={15} style={{ marginRight: '5px' }} />
                </Avatar>
              }
              title="PERMIS DE CONDUIRE"
            />
            <CardBody style={{ paddingTop: '0' }}>
              {employee.EmployeePermis === 0 ? (
                <Alert severity="warning">
                  Pas de permis de conduire enregistré
                </Alert>
              ) : (
                <>
                  <u>Catégorie</u> : {employee.EmployeePermis || '-'}<br />
                </>
              )}
            </CardBody>
          </Card>
        </Box>
        <Box>
        </Box>
      </Box>}
    </>
  )
}

