import React, { useEffect, useState } from 'react';
import GainRetenuService from './service/GainRetenu.jsx';
import Table from '../../components/table/index.jsx';
import Button from '@mui/material/Button';
import ModalComponent from '../../components/modalComponent/index.jsx';
import { useAuth } from '../../applications/hooks/UseAuth.js';
import Notification from '../../components/notificationComponent/index.jsx';
import Controls from '../../components/Entry/Controls.jsx';
import FormControl from '@material-ui/core/FormControl';
import { Chip } from '@mui/material';
import Tooltips from '../../components/tooltips/index.jsx';
import { MdDeleteOutline } from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';
import Zoom from '@material-ui/core/Zoom';
import Autocomplete from '../../components/Autocomplete';
import TextField from '@mui/material/TextField';
import moment from 'moment';

const CURRENT_PAGE = 'gainRetenu';

function ModalContentBody(props) {
    const {editId, valuesGainRetenu, setValuesGainRetenu, isSubmitted, options,fullName, setFullName} = props;
    const [isHour, setIsHour] = useState(false);
    const [variables, setVariables] = useState([]);
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setValuesGainRetenu({
            ...valuesGainRetenu,
            [name]: value
        })
    }

    const checkEmployeeNumberEmpty = () => {
        if (isSubmitted && !valuesGainRetenu.employeeId) {
          return 'Le champ matricule est requis.'
        }
    }

    const checkDateOfEmpty = () => {
        if (isSubmitted && !valuesGainRetenu.dateOf) {
            return 'Le champ date est requis.'
        }
    }
    
    const checkJoursEmpty = () => {
        if (isSubmitted && !valuesGainRetenu.jours) {
            return 'Le champ nombre de jours est requis.'
        } else if (isSubmitted && valuesGainRetenu.jours <= 0) {
            return 'Nombre invalid';
        }
    }
    
    const checkAmountEmpty = () => {
        if (isSubmitted && !valuesGainRetenu.amount) {
            return 'Le champ montant est requis.'
        } else if (isSubmitted && valuesGainRetenu.amount <= 0) {
            return 'Montant invalid';
        }
    }
    
    const checkTypeEmpty = () => {
        if (isSubmitted && !valuesGainRetenu.type) {
            return 'Le champ type est requis.'
        }
    }
    
    const checkCompteEmpty = () => {
        if (isSubmitted && !valuesGainRetenu.compte) {
            return 'Le champ code est requis.'
        }
    }

    const checkValeurEmpty = () => {
        if (isSubmitted && !valuesGainRetenu.valeur) {
            return 'Le champ valeur est requis.'
        }
    }

    const onTagsChangeAdvance = (event, values) => {
        if (values !== null) {
            setValuesGainRetenu({ ...valuesGainRetenu, employeeId: values.id });
            setFullName({ ...fullName, lastName: values.name });
        } else {
            setValuesGainRetenu({ ...valuesGainRetenu, employeeId: null });
            setFullName('');
        }
    }

    useEffect(() => {
        async function getVariable() {
            const response = await GainRetenuService.getAllRubrique();
            if(response.status.success === true) {
                var filter = {
                    type: valuesGainRetenu.type,
                    valeur: valuesGainRetenu.valeur
                };
                const variable = response.data.filter(function(item) {
                    for (var key in filter) {
                        if (item[key] === undefined || item[key] !== filter[key])
                        return false;
                    }
                    return true;
                });
                setVariables(variable)
            }
        }
        getVariable();
    }, [valuesGainRetenu.valeur, valuesGainRetenu.type]);

    useEffect(() => {
        if(editId) {
            GainRetenuService.getSingleGainRetenu(editId).then((res) => {
                if(res.status.success === true) {
                    const dataEdit = {
                        employeeId: res.data.employeeId,
                        type: res.data.type,
                        compte: res.data.compte,
                        valeur: res.data.valeur,
                        dateOf: res.data.dateOf,
                        jours: res.data.jours,
                        amount: res.data.amount,
                    }
                    setValuesGainRetenu(dataEdit)
                }
            });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <form>
            <FormControl style={{ width: '100%', marginBottom: '10px', position: 'relative' }}>
                <Autocomplete
                    editMat={editId}
                    onTagsChange={onTagsChangeAdvance}
                    options={options}
                    lastName={fullName.lastName}
                />
                <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-10px' }}>{checkEmployeeNumberEmpty()}</span>
            </FormControl>
            {!editId && (
                <>
                    <FormControl style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '30px'}} >
                        <label style={{ fontSize: '14px', }}>Valeur</label>
                        <select
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderRight: 'none',
                                borderBottom: '1px solid #ccc'
                            }}
                            value={valuesGainRetenu.valeur}
                            onChange={(e) => {
                                setValuesGainRetenu({ ...valuesGainRetenu, valeur: e.target.value })
                                // setValeurFixe(e.target.value === 'fixe');
                            }}
                        >
                            <option></option>
                            <option value="fixe">Fixe</option>
                            <option value="dynamique">Dynamique</option>
                        </select>
                        <span style={{ position: 'absolute', fontSize: '0.75rem', bottom: '-20px', color: '#d32f2f' }}>{checkValeurEmpty()}</span>
                    </FormControl>
                    <FormControl style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '30px'}} >
                        <label style={{ fontSize: '14px', }}>Type</label>
                        <select
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderRight: 'none',
                                borderBottom: '1px solid #ccc'
                            }}
                            value={valuesGainRetenu.type}
                            onChange={(e) => {
                                setValuesGainRetenu({ ...valuesGainRetenu, type: e.target.value })
                            }}
                        >
                            <option></option>
                            <option value="gain">Gain</option>
                            <option value="retenue">Retenue</option>
                        </select>
                        <span style={{ position: 'absolute', fontSize: '0.75rem', bottom: '-20px', color: '#d32f2f' }}>{checkTypeEmpty()}</span>
                    </FormControl>
                    <FormControl style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '30px'}} >
                        <label style={{ fontSize: '14px', }}>Rubrique</label>
                        <select
                            style={{
                                width: '100%',
                                padding: '8px',
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderRight: 'none',
                                borderBottom: '1px solid #ccc'
                            }}
                            value={valuesGainRetenu.compte}
                            onChange={(e) => {
                                let newValue = e.target.value.split('-');
                                setIsHour(parseInt(newValue[1]) === 1);
                                setValuesGainRetenu({ ...valuesGainRetenu, compte: e.target.value });
                            }}
                        >
                            <option></option>
                            {variables.map((vars) => (
                            <option key={vars.id} value={vars.compte+'-'+vars.divider}>
                                {vars.compte} - {vars.variableName}
                            </option>
                            ))}
                        </select>
                        <span style={{ position: 'absolute', fontSize: '0.75rem', bottom: '-20px', color: '#d32f2f' }}>{checkCompteEmpty()}</span>
                    </FormControl>
                </>
            )
            }
            <FormControl style={{ width: '100%', marginBottom: '30px' }}>
                <Controls.DatePicker
                    name="dateOf"
                    label="Période"
                    value={valuesGainRetenu.dateOf}
                    onChange={handleInputChange}
                />
                <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-20px' }}>{checkDateOfEmpty()}</span>
            </FormControl>
            {valuesGainRetenu.valeur === 'dynamique' && 
                <FormControl style={{ width: '100%', marginBottom: '30px' }}>
                    <TextField
                        type="number"
                        label={isHour ? "Nombre d'heures" : "Nombre de jours"}
                        value={valuesGainRetenu.jours}
                        inputProps={{
                            step: (isHour ? 1 : 0.5)
                        }}
                        variant="outlined"
                        onChange={(e) => {
                            setValuesGainRetenu({ ...valuesGainRetenu, jours: e.target.value })
                        }}
                    />
                    <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-20px' }}>{checkJoursEmpty()}</span>
                </FormControl>
            }
            {valuesGainRetenu.valeur === 'fixe' &&
                <FormControl style={{ width: '100%', marginBottom: '30px' }}>
                    <TextField
                        type="number"
                        label="Montant(MGA)"
                        value={valuesGainRetenu.amount}
                        variant="outlined"
                        onChange={(e) => {
                            setValuesGainRetenu({ ...valuesGainRetenu, amount: e.target.value })
                        }}
                    />
                    <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-20px' }}>{checkAmountEmpty()}</span>
                </FormControl>
            }
        </form>
    )
}

function GainRetenu() {
    const { haveAccessTo } = useAuth();

    const initialeValueGainRetenu = {
        employeeId : null,
        dateOf: new Date(),
        jours: '',
        amount : '',
        type: 'gain',
        compte: '',
        valeur: 'fixe',
    }
    const [rows, setRows] = useState([]);
    const [openAddEdit, setOpenAddEdit] = useState(false);
    const [valuesGainRetenu, setValuesGainRetenu] = useState(initialeValueGainRetenu);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [openNotif, setOpenNotif] = useState(false);
    const [messageNotif, setMessageNotif] = useState('');
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [deletedId, setDeletedId] = useState(null);
    const [editId, setEditId] = useState(null);
    const [severityPerso, setSeverityPerso] = useState("");
    const [options, setOptions] = useState([]);
    const [fullName, setFullName] = useState({
        lastName: ''
    });

    useEffect(() => {
        async function getEmpoyee() {
            const response = await GainRetenuService.getAllEmployee();
            if(response.status.success === true) {
                setOptions(response.data);
            }
        }
        getEmpoyee();
    }, []);

    const getListGainRetenu = async () => {
        const response = await GainRetenuService.getAllGainRetenu()
        const newRows = []
        if (response.status.code === 200) {
            response.data.forEach(el => {
                newRows.push({
                    id: el.id,
                    matricule: el.matricule,
                    name: el.lastName + " " + el.firstName,
                    compte: el.compte,
                    variableName: el.variableName,
                    type: el.type,
                    jours: el.jours ? `${el.jours} ${el.divider !== 1 ? 'jours' : 'heures'}` : '-',
                    dateOf: el.dateOf ? moment(el.dateOf).format('DD MMM YYYY') : '-',
                    amount: el.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                });
            });
        }
        setRows(newRows);
    }

    useEffect(() => {
        getListGainRetenu();
    }, [])

    const closeModal = () => {
        setFullName('');
        setEditId(null);
        setOpenAddEdit(false);
        setValuesGainRetenu(initialeValueGainRetenu);
        setIsSubmitted(false);
    }

    const handleCloseNotif = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageNotif("");
        setOpenNotif(false);
        setSeverityPerso("");
    };

    const handleEdit = (data) => () => {
        setFullName({ ...fullName, lastName: data.row.name });
        setEditId(data.id);
        setOpenAddEdit(true);
    }

    const columns = [
        {
            field: 'matricule',
            headerName: 'Matricule',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'name',
            headerName: 'Nom/Prénoms',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'compte',
            headerName: 'Compte',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
        },
        {
            field: 'variableName',
            headerName: 'variable',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
        },
        {
            field: 'type',
            headerName: 'Type',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (params) => {
              if (!params?.value) {
                return '-';
              }
              const label = params?.value?.replace(
                /^./,
                params?.value[0]?.toUpperCase()
              );
              return params?.value === 'gain' ? (
                <Chip label={label} color="success" variant="outlined" />
              ) : (
                <Chip label={label} color="error" variant="outlined" />
              );
            },
        },
        {
            field: 'jours',
            headerName: 'Nombre',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'dateOf',
            headerName: 'Date',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'amount',
            headerName: 'Montant',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'action',
            type: 'actions',
            headerName: 'Action',
            align: 'center',
            headerAlign: 'center',
            width: 200,
            getActions: (params) => {
                const handleDelete = async (id) => {
                    setOpenDeleteConfirm(true);
                    setDeletedId(id);
                }

                return [
                    <>
                        <Tooltips TransitionComponent={Zoom} title="Supprimer">
                            <Button
                                // className="event-delete-btn contentBtnStyle forAllBtn"
                                // disabled={!haveAccessTo(CURRENT_PAGE, 'delete')}
                                onClick={() => { handleDelete(params.id) }}
                            >
                                <MdDeleteOutline className='actionIconStyle' />
                            </Button>
                        </Tooltips>
                        <Tooltips TransitionComponent={Zoom} title="Modifier">
                            <Button
                                // className="event-edit-btn contentBtnStyle forAllBtn"
                                // disabled={!haveAccessTo(CURRENT_PAGE, 'edit')}
                                onClick={handleEdit(params)}
                            >
                                <FaRegEdit className='actionIconStyle'/>
                            </Button>
                        </Tooltips>
                    </>
                ]
            }
        },
    ]

    const onSaveNewGainRetenu = async () => {
        if (haveAccessTo(CURRENT_PAGE, 'add')) {
            setIsSubmitted(true);
            const validate =
                valuesGainRetenu.valeur === 'fixe' ?
                valuesGainRetenu.employeeId &&
                valuesGainRetenu.dateOf &&
                valuesGainRetenu.amount &&
                valuesGainRetenu.type &&
                valuesGainRetenu.valeur &&
                valuesGainRetenu.compte
                :
                valuesGainRetenu.employeeId &&
                valuesGainRetenu.dateOf &&
                valuesGainRetenu.jours &&
                valuesGainRetenu.type &&
                valuesGainRetenu.valeur &&
                valuesGainRetenu.compte

          if (validate) {
            if(editId) {
                const response = await GainRetenuService.editGainRetenu(editId, valuesGainRetenu);
                if(response.status.success === true) {
                    let dataResponse = response.data;
                    setRows(
                        rows.map((row) => {
                            const updateData = {
                                ...row,
                                jours: dataResponse.jours ? `${dataResponse.jours} ${dataResponse.divider !== 1 ? 'jours' : 'heures'}` : '-',
                                dateOf: dataResponse.dateOf ? moment(dataResponse.dateOf).format('DD MMM YYYY') : '-',
                                amount: dataResponse.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                            }
                          return row.id === response.data.id ? updateData : row;
                        })
                      );
                    closeModal();
                    setSeverityPerso("success");
                    setOpenNotif(true);
                    setMessageNotif("La modification a réussi.");
                }
            } else {
                const response = await GainRetenuService.addGainRetenu(valuesGainRetenu);
                if(response.status.code === 200) {
                    let dataResponse = response.data;
                    const addData = {
                        id: dataResponse.id,
                        matricule: dataResponse.matricule,
                        name: dataResponse.name,
                        compte: dataResponse.compte,
                        variableName: dataResponse.variableName,
                        type: dataResponse.type,
                        jours: dataResponse.jours ? `${dataResponse.jours} ${dataResponse.divider !== 1 ? 'jours' : 'heures'}` : '-',
                        dateOf: dataResponse.dateOf ? moment(dataResponse.dateOf).format('DD MMM YYYY') : '-',
                        amount: dataResponse.amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                    }
                    setRows([...rows, addData])
                    closeModal();
                    setSeverityPerso("success");
                    setOpenNotif(true);
                    setMessageNotif("L'ajout a réussi.");
                } else {
                    setSeverityPerso("error");
                    setOpenNotif(true);
                    setMessageNotif(response.status.message);
                }
            }
          }
        }
    };

    const confirmDelete = async () => {
        const response = await GainRetenuService.deleteGainRetenu(deletedId);
        if(response.status.code === 200) {
            setSeverityPerso("success");
            setDeletedId(null);
            setMessageNotif(`Suppression avec succès.`);
            setRows(rows.filter((el) => el.id !== deletedId));
            setOpenDeleteConfirm(false);
            setOpenNotif(true);
        }
    }

    return (
        <>
            <Notification
                severity={severityPerso}
                openNotif={openNotif}
                handleClose={handleCloseNotif}
                message={messageNotif}
                duration={2000}
            />
            <div style={{ padding: 20 }}>
                <div style={{ marginBottom: '30px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <h4 style={{ margin: 0 }}>Entrée des rubriques</h4>
                    <Button
                        style={{textTransform: 'capitalize' }}
                        variant="contained"
                        size="small"
                        onClick={() => setOpenAddEdit(true)}
                    >
                        Ajout
                    </Button>
                </div>
                <Table columns={columns} rows={rows}/>
            </div>
            <ModalComponent
                openModal={openAddEdit}
                close={() => closeModal()}
                title={!editId ? 'Ajout' : 'Modifier'}
                submit={() => onSaveNewGainRetenu()}
                actionTitle={'Enregistrer'}
                modalContentBody={<ModalContentBody fullName={fullName} setFullName={setFullName} editId={editId} options={options} setValuesGainRetenu={setValuesGainRetenu} valuesGainRetenu={valuesGainRetenu} isSubmitted={isSubmitted} />}
            />
            <ModalComponent
                openModal={openDeleteConfirm}
                close={() => {
                    setOpenDeleteConfirm(false)
                    setDeletedId(null);
                }}
                title={'Suppression'}
                submit={() => confirmDelete()}
                actionTitle={'Confirmer'}
                modalContentBody={'Est-ce que vous voulez vraiment supprimer cet GainRetenu?'}
            />
        </>
    )
}

export default GainRetenu;