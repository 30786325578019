import { Http } from '../../../applications/Http.js';
import Service from '../../../applications/Service.js';

class ConfigEmailService extends Service {
    static messages = {
        update_success: " updated.",
        update_error: "Email config not updated.",
        delete_success: "Email config deleted.",
        delete_error: "Email config not deleted.",
        create_success: "Email config created.",
        create_error: "Email config not created."
    }
    static load() {
        this.setUrl('/api/config');
        return this;
    }

    static async getAllConfig() {
        return await Http.get(this.url,{});
    }

    static async updateConfig( params) {
        return await Http.put(this.url, {...params});
    }
    static async updateConfigById(configId, params) {
        return await Http.put([this.url, configId].join("/"), {...params});
    }
}

export default ConfigEmailService;