import React from "react";
import { Paper, Card, Typography } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F5F5F5 !important",
    display: "flex",
    justifyContent: "space-between",
  },
  pageHeader: {
    padding: "0 10px 5px",
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  pageIcon: {
    display: "inline-block",
    padding: theme.spacing(2),
    color: "#3c44b1",
  },
  pageTitle: {
    paddingLeft: theme.spacing(1),
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
    marginTop: "5px",
  },
}));

export default function PageHeader(props) {
  const classes = useStyles();
  const { title, subTitle, icon } = props;
  return (
    <Paper elevation={0} square className={classes.root}>
      <div className={classes.pageHeader}>
        {icon && (
          <Card className={classes.pageIcon} style={{ margin: "auto 0" }}>
            {icon}
          </Card>
        )}
        <div className={classes.pageTitle}>
          <Typography
            variant="h6"
            component="div"
            className="whitespace-nowrap"
          >
            {title}
          </Typography>
          <Typography variant="subtitle2" component="div">
            {subTitle}
          </Typography>
        </div>
      </div>
      {props.children}
    </Paper>
  );
}
