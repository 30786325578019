import React, { useEffect, useContext, useState } from 'react'
import { Grid } from '@mui/material'
import Controls from '../../../components/Entry/Controls'
import FormControl from '@material-ui/core/FormControl';
import {AddEmployeeContext} from './index.jsx';
import BigNumber from 'bignumber.js';

const paymentMethodItems = [
    { id: 1, title: 'Espèces' },
    { id: 2, title: 'Virement bancaire' },
    { id: 3, title: 'Chèque' },
    { id: 4, title: 'Mobile money' }
]

export default function Contract(props) {
    const { errors, setErrors, valuesOther, setValuesOther, addEmployeeData, setAddEmployeeData } = useContext(AddEmployeeContext)
    const [iban, setIban] = useState(null);

    const verifyRIB = (rib) => {
        const numeroRIB =  rib.replace(/ /g, '');
        if (!numeroRIB) {
          return false;
        }
    
        const ribSansCle = numeroRIB.slice(0, -2) + '00';
        const ribChiffres = ribSansCle
          .replace(/[AJ]/g, '1')
          .replace(/[BKS]/g, '2')
          .replace(/[CLT]/g, '3')
          .replace(/[DMU]/g, '4')
          .replace(/[ENV]/g, '5')
          .replace(/[FOW]/g, '6')
          .replace(/[GPX]/g, '7')
          .replace(/[HQY]/g, '8')
          .replace(/[IRZ]/g, '9');
    
        const modulo = BigNumber(ribChiffres).modulo(97).toNumber();
        const checkDigit = 97 - modulo;
    
        return checkDigit === parseInt(numeroRIB.slice(-2));
    };

    const validate = (fieldValues = valuesOther, fieldIban = iban) => {
        let temp = { ...errors }
        if ('cnaps' in fieldValues) temp.cnaps = fieldValues.cnaps ? '' : 'Champ obligatoire.'
        if ('paymentMethod' in fieldValues) temp.paymentMethod = fieldValues.paymentMethod ? '' : 'Champ obligatoire.'
        if (fieldValues.paymentMethod === 2) {
            if ('domiciliation' in fieldValues)
                temp.domiciliation = fieldValues.domiciliation ? '' : 'Champ obligatoire.'
            if ('bankCode' in fieldValues)
                temp.bankCode = fieldValues.bankCode ? '' : 'Champ obligatoire.'
            if ('bankCode' in fieldValues && fieldValues.bankCode)
                temp.bankCode = fieldValues.bankCode.length !== 5 ? '5 chiffres.' : ''
            if ('agencyCode' in fieldValues)
                temp.agencyCode = fieldValues.agencyCode ? '' : 'Champ obligatoire.'
            if ('agencyCode' in fieldValues && fieldValues.agencyCode)
                temp.agencyCode = fieldValues.agencyCode.length !== 5 ? '5 chiffres.' : ''
            if ('accountNumber' in fieldValues)
                temp.accountNumber = fieldValues.accountNumber ? '' : 'Champ obligatoire.'
            if ('accountNumber' in fieldValues && fieldValues.accountNumber)
                temp.accountNumber = fieldValues.accountNumber.length !== 11 ? '11 chiffres.' : ''
            if ('key' in fieldValues) temp.key = fieldValues.key ? '' : 'Champ obligatoire.'
            if ('key' in fieldValues && fieldValues.key)
                temp.key = fieldValues.key.length !== 2 ? '2 chiffres.' : ''
            if (fieldIban && fieldIban.length === 23) {
                temp.iban = !verifyRIB(fieldIban) ? 'Rib invalide' : ''
            } else {
                temp.iban = ''
            }

        } else {
            delete temp.domiciliation
            delete temp.bankCode
            delete temp.agencyCode
            delete temp.accountNumber
            delete temp.key
        }

        for (const key in temp) {
            if (temp[key] === '') {
                delete temp[key]
            }
        }

        setErrors({
            ...temp
        })

        return Object.keys(temp).length === 0
    }

    const resRIB =  valuesOther.bankCode+
                    valuesOther.agencyCode+
                    valuesOther.accountNumber+
                    valuesOther?.key;
  
    useEffect(() => {
        setIban(resRIB);
    }, [resRIB])

    const handleInputChange = (e) => {
        const { name, value } = e.target

        if (name === 'bankCode' || name === 'agencyCode' || name === 'conges') {
            let newValue = value.replace(/[^\d+]/g, '');
            const limitedValue = newValue.slice(0, 5);
            if (limitedValue.length === 5) {
            setValuesOther({ ...valuesOther, [name]: limitedValue })
            }
            setValuesOther({ ...valuesOther, [name]: limitedValue })
        } else if(name === 'accountNumber') {
            let newValue = value.replace(/[^\d+]/g, '');
            const limitedValue = newValue.slice(0, 11);
            if (limitedValue.length === 11) {
            setValuesOther({ ...valuesOther, [name]: limitedValue })
            }
            setValuesOther({ ...valuesOther, [name]: limitedValue })
        } else if(name === 'key') {
            let newValue = value.replace(/[^\d+]/g, '');
            const limitedValue = newValue.slice(0, 2);
            if (limitedValue.length === 2) {
            setValuesOther({ ...valuesOther, [name]: limitedValue })
            }
            setValuesOther({ ...valuesOther, [name]: limitedValue })
        } else {
            setValuesOther({
                ...valuesOther,
                [name]: value
            })
        }
    }

    useEffect(() => {
        validate()
    })

    //handle load
    useEffect(() => {
        !addEmployeeData.other
            ? setAddEmployeeData({
                  ...addEmployeeData,
                  other: {
                      ...valuesOther,
                      contractSocietyStartDate: addEmployeeData.contract.contractStartDate,
                      status: addEmployeeData.contract.status
                  }
              })
            : setValuesOther({
                  ...addEmployeeData.other,
                  contractSocietyStartDate: addEmployeeData.contract.contractStartDate,
                  status: addEmployeeData.contract.status
              })
              // eslint-disable-next-line
    }, [])

    return (
        <form>
            <Grid container>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '30px' }}>
                            <Controls.Input
                                name="conges"
                                label="Solde du congé (en jours)"
                                value={valuesOther.conges}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '30px' }}>
                            <Controls.Input
                                name="cnaps"
                                label="Numéro CNAPS"
                                value={valuesOther.cnaps}
                                onChange={handleInputChange}
                                error={errors.cnaps}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid container>
                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                            <FormControl style={{ width: '25%', marginBottom: '30px' }}>
                                <Controls.Select
                                    name="paymentMethod"
                                    label="Méthode de paiement"
                                    value={valuesOther.paymentMethod}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    options={paymentMethodItems}
                                    error={errors.paymentMethod}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}></Grid>
                    </Grid>
                    {valuesOther.paymentMethod === 2 && (
                        <>
                            <Grid container>
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <FormControl style={{ width: '25%', marginBottom: '30px' }}>
                                        <Controls.Input
                                            name="domiciliation"
                                            label="Domiciliation"
                                            value={valuesOther.domiciliation}
                                            onChange={handleInputChange}
                                            error={errors.domiciliation}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}></Grid>
                            </Grid>
                            <Grid item xs={3} sx={{display: 'flex', justifyContent: 'center'}}>
                                <FormControl style={{ width: '90%', marginBottom: '30px' }}>
                                    <Controls.Input
                                        name="bankCode"
                                        label="Code banque"
                                        value={valuesOther.bankCode}
                                        onChange={handleInputChange}
                                        error={errors.bankCode}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{display: 'flex', justifyContent: 'center'}}>
                                <FormControl style={{ width: '90%', marginBottom: '30px' }}>
                                    <Controls.Input
                                        name="agencyCode"
                                        label="Code guichet"
                                        value={valuesOther.agencyCode}
                                        onChange={handleInputChange}
                                        error={errors.agencyCode}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{display: 'flex', justifyContent: 'center'}}>
                                <FormControl style={{ width: '90%', marginBottom: '30px' }}>
                                    <Controls.Input
                                        name="accountNumber"
                                        label="Numéro de compte"
                                        value={valuesOther.accountNumber}
                                        onChange={handleInputChange}
                                        error={errors.accountNumber}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3} sx={{display: 'flex', justifyContent: 'center'}}>
                                <FormControl style={{ width: '90%', marginBottom: '30px' }}>
                                    <Controls.Input
                                        name="key"
                                        label="Clé"
                                        value={valuesOther.key}
                                        onChange={handleInputChange}
                                        error={errors.key}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container>
                            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', position: 'relative'}}>
                                <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-10px' }}>{errors.iban}</span>
                            </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </form>
    )
}
