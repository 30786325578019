import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardBody from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { Box } from '@material-ui/core';
import moment from 'moment';
// import PrintContract from './PrintContract';
// import PrintPaie from './PrintPaie';
import { CiBank } from 'react-icons/ci';
import { GiTakeMyMoney } from 'react-icons/gi';
import { AiOutlineFileExclamation } from 'react-icons/ai';
import { CiSquareMore } from 'react-icons/ci';
import { FaHandHoldingDollar } from 'react-icons/fa6';
import {
  Table as TableMaterial,
  TableBody,
  TableCell,
  TableContainer,
  // TableHead,
  TableRow,
  Paper
} from '@mui/material';
import unlock from './icons/unlock.jpg';
import bfv from './icons/bfv.png';
import bni from './icons/bni.jpg';
import boa from './icons/boa.png';
import bmoi from './icons/bmoi.png';
import acc from './icons/acc.png';
import mcb from './icons/mcb.png';
import 'moment/locale/fr';
import Table from '../../../components/table/index.jsx';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Chip } from '@mui/material';
import PayrollService from '../../payroll/service/Payroll.jsx';
import { BsFileEarmarkPdf } from 'react-icons/bs';
import { Button } from '@mui/material';
import { CircularProgress } from '@material-ui/core';
import EmployeeService from '../services/Employee.jsx'

moment.locale('Fr');


ContratInfo.propTypes = {
  employee: PropTypes.any,
};


function ibanFormat(iban) {
  if (!iban) return 'aucun IBAN enregistré';
  const ibanFormated = iban.replace(/^(\d{5})(\d{5})(\d{11})(\d{2})$/, '$1 $2 $3 $4');
  return ibanFormated;
}

function ContratInfo(props) {
  const { employee } = props;
  const [rows, setRows] = useState([]);
  const [netSalary, setNetSalary] = useState('');
  const [loading, setLoading] = useState(false);
  
  const columns = [
    {
        field: 'advanceKey',
        headerName: 'Code',
        align: 'center',
        headerAlign: 'center',
        flex: 1
    },
    {
        field: 'somme',
        headerName: 'Montant',
        align: 'center',
        headerAlign: 'center',
        flex: 1
    },
    {
        field: 'advanceType',
        headerName: 'Type',
        align: 'center',
        headerAlign: 'center',
        flex: 1
    },
    {
        field: 'status',
        type: 'actions',
        headerName: 'Status',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        getActions: (params) => {
            const status = {
                1: { title: 'A valider', color: 'warning', icon: <ErrorOutlineOutlinedIcon /> },
                2: { title: 'A payer', color: 'error', icon: <DoNotDisturbAltOutlinedIcon /> },
                3: { title: "En cours", color: 'info', icon: <AccessTimeOutlinedIcon /> },
                4: { title: 'Payer', color: 'success', icon: <CheckCircleOutlineOutlinedIcon /> }
            }

            return [
                <Chip
                    label={status[params.row.status]?.title}
                    color={status[params.row.status]?.color}
                    icon={status[params.row.status]?.icon}
                    size="small"
                    variant="outlined"
                />
            ]
        }
    },
    {
        field: 'advanceInsertDate',
        headerName: "Date de la demande",
        align: 'center',
        headerAlign: 'center',
        flex: 1
    },
    {
        field: 'advanceStartDate',
        headerName: "Début du remboursement",
        align: 'center',
        headerAlign: 'center',
        flex: 1
    },
  ]

  useEffect(() => {
    const newRows = []
    employee.AdvanceLine.forEach(el => {
      newRows.push({
        id: el.id,
        advanceKey: el.advanceKey,
        somme: parseFloat(el.somme).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + " MGA",
        advanceType: el.advanceType,
        status: el.status,
        advanceInsertDate: moment(el.advanceInsertDate).format('DD MMMM YYYY'),
        advanceStartDate: el.advanceStartDate ? moment(el.advanceStartDate).format('MMMM YYYY') : "",
      });
    });
    setRows(newRows);
    // eslint-disable-next-line
  }, []);

  const getEmployeePayslipData = async () => {
    const response = await PayrollService.getSalaryNetBrut(employee.id);
    if(response.status.code === 200) {
        setNetSalary((response.data.NetSalary || 0));
    }
  }

  const downloadPDF = () => {
    setLoading(true);
    EmployeeService.printPdfContract(employee.id).then(
        (res) => {
            const pdf64 = res.data.content;
            let a = document.createElement("a");
            a.href = "data:application/octet-stream;base64,"+pdf64;
            a.download = `contrat-${res.data.matricule}.pdf`
            a.click();
            setLoading(false);
        },
        () => {
            setLoading(false);
        }
    )
}

  useEffect(() => {
      getEmployeePayslipData();
      // eslint-disable-next-line
  }, []);

  return (
    <>
      {Object.keys(employee).length !== 0 &&
      <Box>
        <Box style={{ display: 'flex', justifyContent: "space-between", marginBottom: '20px', alignItems: 'center' }}>
          <Box style={{ width: '49%' }}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe">
                    <AiOutlineFileExclamation size={15} />
                  </Avatar>
                }
                title="INFORMATION DU CONTRAT"
              />
              <CardBody>
                <Box>
                  <Box>
                    <u>Direction</u> : {employee.EmployeeContract[0].department}
                  </Box>
                  <Box>
                    <u>Fonction</u> : {employee.EmployeeContract[0].jobTitle}
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <u>Type de contrat</u> : {employee.EmployeeContract[0].contractType.toUpperCase()}
                  </Box>
                  <Box>
                    <u>Début du contrat</u> : {employee.EmployeeContract[0].contractStartDate ? moment(employee.EmployeeContract[0].contractStartDate).format('DD MMMM YYYY') : ""}
                  </Box>
                  {employee.EmployeeContract[0].contractEndDate && (
                    <Box>
                      <u>Fin contrat</u> : {employee.EmployeeContract[0].contractEndDate ? moment(employee.EmployeeContract[0].contractEndDate).format('DD MMMM YYYY') : ""}
                    </Box>
                  )}
                </Box>
                <Box>
                  {/* <Box>
                    <u>Date d&apos;embauche</u> : {employee?.contract?.hiringDate ? moment(employee?.contract?.hiringDate).format('DD/MM/YYYY') : ""}
                  </Box> */}

                  <Box>
                    <u>Ancienneté</u> : {employee.EmployeeContract[0].seniority}
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <u>Groupe</u> : {employee.EmployeeContract[0].group}
                  </Box>
                  <Box>
                    <u>Catégorie</u> : {employee.EmployeeContract[0].category}
                  </Box>
                  <Box>
                    <u>Sérvice</u> : {employee.EmployeeContract[0].service}
                  </Box>
                </Box>
                <Box style={{ textAlign: 'right', marginTop: '20px' }}>
                  <Button disabled={loading} onClick={downloadPDF}>
                    { 
                        loading &&
                        <CircularProgress style={{ width: '30px', height: '30px', position: 'absolute', left: '0px' }} color="inherit" />
                    }
                    <BsFileEarmarkPdf style={{ marginRight: '5px' }} />
                    Contrat (.pdf)
                  </Button>
                </Box>
              </CardBody>
            </Card>
          </Box>
          <Box style={{ width: '49%' }}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe">
                    <GiTakeMyMoney size={15}/>
                  </Avatar>
                }
                title="SALAIRE"
              />
              <CardBody style={{ paddingTop: '0', margin : 'auto' }}>
                <Box>
                  <Box>
                    <Box style={{ display: 'flex', justifyContent: "space-between" }}>
                      <span style={{ fontSize: '17px' }}>Base :</span>
                      <span style={{ fontSize: '20px' }}>
                        {(employee.EmployeeContract[0].baseSalary || 0)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{' '}Ar
                      </span>
                    </Box>
                  </Box>
                  <Box>
                    <Box style={{ display: 'flex', justifyContent: "space-between" }}>
                      <span style={{ fontSize: '17px' }}>Net :</span>
                      <span style={{ fontSize: '20px' }}>
                        {' '}
                        {(netSalary || 0)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{' '}Ar
                      </span>
                    </Box>
                    <Box style={{ width: '100%', textAlign: 'right' }}>
                      
                    </Box>
                  </Box>
                </Box>
                <Box
                  style={{ textAlign: 'right', width: '100%', marginTop: '20px' }}
                >
                  {/* <PrintPaie
                    employeeNumber={employee?.employee?.employeeNumber}
                  /> */}
                </Box>
              </CardBody>
            </Card>
          </Box>
        </Box>
        {employee.AdvanceLine.length > 0 && 
          <Box style={{ width: '100%', marginBottom: '20px' }}>
            <Card style={{ width: '100%' }}>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe">
                    <FaHandHoldingDollar size={15}/>
                  </Avatar>
                }
                title="AVANCE SUR SALAIRE"
              />
              <CardBody>
                <Table columns={columns} rows={rows}/>
              </CardBody>
            </Card>
          </Box>}
        <Box style={{display: 'flex', justifyContent: "space-between", marginBottom: '20px' }}>
          {employee.EmployeeSocietyInfo[0].paymentMethod === 2 &&
            <Box style={{ width: '49%' }}>
              <Card style={{ width: '100%' }}>
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe">
                      <CiBank size={15}/>
                    </Avatar>
                  }
                  title="RELEV&Eacute; D&apos;IDENTIT&Eacute; BANCAIRE"
                />
                <CardBody style={{ paddingTop: '0' }}>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Box style={{ display: 'flex' }}>

                      {employee.EmployeeSocietyInfo[0].EmployeeBank[0].bankCode === '00004' && (
                        <img src={bmoi} style={{ width: '50px', height: '50px' }} alt="BMOI"/>
                      )}
                      {employee.EmployeeSocietyInfo[0].EmployeeBank[0].bankCode === '00005' && (
                        <img src={bni} style={{ width: '50px', height: '50px' }} alt="BNI"/>
                      )}
                      {employee.EmployeeSocietyInfo[0].EmployeeBank[0].bankCode === '00006' && (
                        <img src={mcb} style={{ width: '50px', height: '50px' }} alt="MCB"/>
                      )}
                      {employee.EmployeeSocietyInfo[0].EmployeeBank[0].bankCode === '00008' && (
                        <img src={bfv} style={{ width: '50px', height: '50px' }} alt="BFV"/>
                      )}
                      {employee.EmployeeSocietyInfo[0].EmployeeBank[0].bankCode === '00009' && (
                        <img src={boa} style={{ width: '50px', height: '50px' }} alt="BOA"/>
                      )}
                      {employee.EmployeeSocietyInfo[0].EmployeeBank[0].bankCode === '00011' && (
                        <img src={acc} style={{ width: '50px', height: '50px' }} alt="ACC"/>
                      )}

                      {employee.EmployeeSocietyInfo[0].EmployeeBank[0].bankCode !== '00004'
                        && employee.EmployeeSocietyInfo[0].EmployeeBank[0].bankCode !== '00005'
                        && employee.EmployeeSocietyInfo[0].EmployeeBank[0].bankCode !== '00006'
                        && employee.EmployeeSocietyInfo[0].EmployeeBank[0].bankCode !== '00008'
                        && employee.EmployeeSocietyInfo[0].EmployeeBank[0].bankCode !== '00009'
                        && employee.EmployeeSocietyInfo[0].EmployeeBank[0].bankCode !== '00011'
                        && (
                          <img src={unlock} alt="unlock" style={{ width: '50px', height: '50px' }} />
                        )}
                    </Box>

                    <Box style={{ marginLeft: '20px' }}>
                      <Box style={{ display: 'flex', width: '100%' }}>
                        <u>Titulaire</u>&nbsp;: {employee.lastName} &nbsp; {employee.firstName}
                      </Box>

                      <Box style={{ display: 'flex', width: '100%' }}>
                        <u>Domicilation</u>&nbsp;:  {employee.EmployeeSocietyInfo[0].EmployeeBank[0].domiciliation ? employee.EmployeeSocietyInfo[0].EmployeeBank[0].domiciliation : '-'}
                      </Box>
                    </Box>
                  </Box>

                  <Box style={{ marginTop: '10px', width: '100%' }}>
                    <TableContainer style={{ border: '1px Solid #00000017', borderBottom: 'none' }} component={Paper}>
                      <TableMaterial>
                        <TableBody>
                          <TableRow>
                            <TableCell> <strong>BANQUE</strong> <><br /></> {employee.EmployeeSocietyInfo[0].EmployeeBank[0].bankCode} </TableCell>
                            <TableCell> <strong>GUICHET</strong><><br /></> {employee.EmployeeSocietyInfo[0].EmployeeBank[0].agencyCode} </TableCell>
                            <TableCell> <strong>COMPTE</strong><><br /></> {employee.EmployeeSocietyInfo[0].EmployeeBank[0].accountNumber} </TableCell>
                            <TableCell> <strong>CL&Eacute;</strong><><br /></> {employee.EmployeeSocietyInfo[0].EmployeeBank[0].key} </TableCell>
                          </TableRow>
                        </TableBody>
                      </TableMaterial>
                    </TableContainer>
                  </Box>

                  <Box style={{ marginTop: '10px', width: '100%' }}>
                    <TableContainer style={{ border: '1px Solid #00000017', borderBottom: 'none' }} component={Paper}>
                      <TableMaterial>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ width: '70px' }}><strong>R.I.B.</strong></TableCell>
                            <TableCell>
                              {ibanFormat(employee.EmployeeSocietyInfo[0].EmployeeBank[0].rib)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </TableMaterial>
                    </TableContainer>
                  </Box>
                </CardBody>
              </Card>
            </Box>
          }
          <Box style={{ width: '49%' }}>
            <Card style={{ width: '100%', height: '100%' }}>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe">
                    <CiSquareMore size={15} style={{ marginRight: '5px' }} />
                  </Avatar>
                }
                title="AUTRES"
              />
              <CardBody>
                <Box>
                  <Box>
                    <u>CNAPS</u> : {employee.EmployeeSocietyInfo[0].cnaps}
                  </Box>
                  {employee.EmployeeSocietyInfo[0].contractSocietyStartDate &&
                    <Box>
                      <u>Date de début contrat auprès de la société</u> : {moment(employee.EmployeeSocietyInfo[0].contractSocietyStartDate).format('DD MMMM YYYY')}
                    </Box>
                  }
                  {employee.EmployeeSocietyInfo[0].contractSocietyEndDate &&
                    <Box>
                      <u>Date de fin contrat auprès de la société</u> : {moment(employee.EmployeeSocietyInfo[0].contractSocietyEndDate).format('DD MMMM YYYY')}
                    </Box>
                  }
                </Box>
              </CardBody>
            </Card>
          </Box>
        </Box>
      </Box>}
    </>
  );
}
export default ContratInfo;
