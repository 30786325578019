import React, { useEffect, useState } from 'react';
import SanctionService from './service/Sanction.jsx';
import Table from '../../components/table/index.jsx';
import moment from 'moment';
import Button from '@mui/material/Button';
import ModalComponent from '../../components/modalComponent/index.jsx';
import { useAuth } from '../../applications/hooks/UseAuth';
import Notification from '../../components/notificationComponent/index.jsx';
import Controls from '../../components/Entry/Controls.jsx';
// import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '../../components/Autocomplete';
import { CircularProgress } from '@material-ui/core';
import { LiaCloudUploadAltSolid } from 'react-icons/lia';
import { MdAddCircleOutline } from 'react-icons/md';

const CURRENT_PAGE = 'sanction';

// const styles = {
//     selectHours: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         marginLeft: '20px',
//         width: '80px',
//         height: '60px'
//     },
//     congeType: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         marginBottom: '20px'
//     },
//     selectContent: {
//         color: 'GrayText',
//         width: '100%',
//         padding: '8px',
//         borderTop: 'none',
//         borderLeft: 'none',
//         borderRight: 'none',
//         borderBottom: '1px solid #ccc',
//       },
// }

// const useStyles = makeStyles(styles);

function ModalContentBody(props) {
    const {valuesSanction, setValuesSanction, isSubmitted, options} = props;
    // const classes = useStyles();
    const [fullName, setFullName] = useState({
        lastName: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setValuesSanction({
            ...valuesSanction,
            [name]: value
        })
    }

    const checkEmployeeNumberEmptySanction = () => {
        if (isSubmitted && !valuesSanction.employeeId) {
          return 'Le champ matricule est requis.'
        }
    }
    
    const checkDateEmptySanction = () => {
        if (isSubmitted && !valuesSanction.date) {
            return 'Le champ date est requis.'
        }
    }

    const checkEmptySanctionType = () => {
        if (isSubmitted && !valuesSanction.type) {
            return 'Ce champ est requis.'
        }
    }

    const onTagsChangeSanction = (event, values) => {
        if (values !== null) {
            setValuesSanction({ ...valuesSanction, employeeId: values.id });
            setFullName({ ...fullName, lastName: values.name });
        } else {
            setValuesSanction({ ...valuesSanction, employeeId: null });
            setFullName('');
        }
    }

    return (
        <form>
            <FormControl style={{ width: '100%', marginBottom: '10px', position: 'relative' }}>
                <Autocomplete
                    editMat={null}
                    onTagsChange={onTagsChangeSanction}
                    options={options}
                    lastName={fullName.lastName}
                />
                <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-13px' }}>{checkEmployeeNumberEmptySanction()}</span>
            </FormControl>
            <FormControl style={{ width: '100%', marginTop: '20px' }}>
                <Controls.DatePicker
                    name="date"
                    label="Date"
                    value={valuesSanction.date}
                    onChange={handleInputChange}
                    error={checkDateEmptySanction()}
                />
                <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-13px' }}>{checkDateEmptySanction()}</span>
            </FormControl>
            <FormControl style={{ width: '100%', marginTop: '20px' }}>
                <Controls.Textarea
                    sx={{ width: '100%', marginBottom: '10px' }}
                    name="type"
                    label="Saisissez un type de sanction"
                    value={valuesSanction.type}
                    onChange={handleInputChange}
                    error={checkEmptySanctionType()}
                />
            </FormControl>
        </form>
    )
}

function Sanction() {
    const { haveAccessTo } = useAuth();
    const initialeValueSanction = {
        employeeId: null,
        type: '',
        date: new Date(),
    }
    const [rows, setRows] = useState([]);
    const [openAddEdit, setOpenAddEdit] = useState(false);
    const [valuesSanction, setValuesSanction] = useState(initialeValueSanction);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [openNotif, setOpenNotif] = useState(false);
    const [messageNotif, setMessageNotif] = useState('');
    const [options, setOptions] = useState([]);
    const [severityPerso, setSeverityPerso] = useState("");
    const [isClickExport, setIsClickExport] = useState(false);

    useEffect(() => {
        async function getEmpoyee() {
            const response = await SanctionService.getAllEmployee();
            if(response.status.success === true) {
                setOptions(response.data);
            }
        }
        getEmpoyee();
      }, []);

    const getListEmploye = async () => {
        const response = await SanctionService.getAllSanction()
        const newRows = []
        if (response.status.code === 200) {
            response?.data?.forEach(el => {
                newRows.push({
                  id: el.id,
                  matricule: el.matricule,
                  fullName: el.lastName + ' ' + el.firstName,
                  type: el.type,
                  date: moment(el.date).format('DD MMMM YYYY'),
                });
            });
        }
        setRows(newRows);
    }

    useEffect(() => {
        getListEmploye();
    }, [])

    const closeModal = () => {
        setOpenAddEdit(false);
        setValuesSanction(initialeValueSanction);
        setIsSubmitted(false);
    }

    const handleCloseNotif = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageNotif("");
        setOpenNotif(false);
        setSeverityPerso("");
    };

    const columns = [
        {
            field: 'matricule',
            headerName: 'Matricule',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'fullName',
            headerName: 'Nom et Prénoms',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'type',
            headerName: 'Type',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'date',
            headerName: 'Date',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        }
    ]

    const onSaveNewConge = async () => {
        if (haveAccessTo(CURRENT_PAGE, 'add')) {
          setIsSubmitted(true);
          if (
            valuesSanction.employeeId &&
            valuesSanction.type &&
            valuesSanction.date
          ) {
            const response = await SanctionService.addSanction(valuesSanction);
            if(response.status.code === 200) {
                let dataResponse = response.data;
                const addData = {
                    id: dataResponse.id,
                    matricule: dataResponse.matricule,
                    fullName: dataResponse.lastName + ' ' + dataResponse.firstName,
                    type: dataResponse.type,
                    date: moment(dataResponse.date).format('DD MMMM YYYY'),
                }
                setRows([...rows, addData])
                closeModal();
                setSeverityPerso("success");
                setOpenNotif(true);
                setMessageNotif("Sanction ajouter avec succès.");
            } else {
                setSeverityPerso("error");
                setOpenNotif(true);
                setMessageNotif(response.status.message);
            }
          }
        }
    };

    const onExportExcel = async () => {
        setIsClickExport(true);
        SanctionService.generateExcel({}).then((res) => {
          setIsClickExport(false);
          let a = document.createElement('a');
          a.href = 'data:application/octet-stream;base64,' + res.data.content;
          a.download = `sanctions.xlsx`;
          a.click();
        });
    };

    return (
        <>
            <Notification
                severity={severityPerso}
                openNotif={openNotif}
                handleClose={handleCloseNotif}
                message={messageNotif}
            />
            <div style={{ padding: 20 }}>
                <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <h4 style={{ margin: 0 }}>Sanction</h4>
                    <div>
                        <Button
                            disabled={isClickExport}
                            style={{ textTransform: 'capitalize' }}
                            variant="text"
                            size="small"
                            onClick={onExportExcel}
                        >
                            { 
                                isClickExport &&
                                <CircularProgress style={{ width: '16px', height: '16px', position: 'absolute', left: '3px' }} color="inherit" />
                            }
                            <LiaCloudUploadAltSolid className='actionIconStyle' />
                            Exporter
                        </Button>
                        <Button
                            style={{ textTransform: 'capitalize' }}
                            variant="text"
                            size="small"
                            onClick={() => setOpenAddEdit(true)}
                        >
                            <MdAddCircleOutline className='actionIconStyle' />
                            Ajouter
                        </Button>
                    </div>
                </div>
                <Table columns={columns} rows={rows}/>
            </div>
            <ModalComponent
                openModal={openAddEdit}
                close={() => closeModal()}
                title={'Ajout'}
                submit={() => onSaveNewConge()}
                actionTitle={'Enregistrer'}
                modalContentBody={<ModalContentBody options={options} setValuesSanction={setValuesSanction} valuesSanction={valuesSanction} isSubmitted={isSubmitted} />}
            />
        </>
    )
}

export default Sanction;