import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import useStyles from './style.jsx';
import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../applications/hooks/UseAuth';
import { setToken } from '../../applications/reducers/AuthSlice';
import { useDispatch } from 'react-redux';
import UserService from './services/User';
import logo from '../../assets/img/logo.png';
import { CircularProgress } from '@material-ui/core';

const Login = () => {
  const navigate = useNavigate()
  const classes = useStyles();
  const emailRef = useRef();
  const errRef = useRef();
  // const { token } = useAuth();
  const [errMsg, setErrMsg] = useState('')
  const [stateLogin, setStateLogin] = useState({
    email: "",
    password: ""
  });
  const [ loading, setLoading] = useState(false);

  const dispatch = useDispatch()

  const handleChange = (e) => {
    setStateLogin({...stateLogin, [e.currentTarget.id]: e.currentTarget.value });
  };

  const login = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const user = await UserService.login(stateLogin)
      dispatch(setToken({ token: user.data.access_token }))
      sessionStorage.setItem('token', user.data.access_token)
      setStateLogin({
        email: "",
        password: ""
      })
      navigate('/app/dashboard');
      setLoading(false);
    } catch (err) {
        if (!err?.originalStatus) {
            // isLoading: true until timeout occurs
            setErrMsg('No Server Response')
        } else if (err.originalStatus === 400) {
            setErrMsg('Missing Username or Password')
        } else if (err.originalStatus === 401) {
            setErrMsg('Unauthorized')
        } else {
            setErrMsg('Login Failed')
        }
        setLoading(false);
        errRef.current.focus()
    }
  }

  return (
    <>
      <div className={classes.login_content}>
        <img style={{ width: '320px', objectFit: 'contain' }} src={logo} alt="user" />
        <form onSubmit={login}>
          <TextField
            label="Email"
            id="email"
            value={stateLogin.email}
            onChange={handleChange}
            type="text"
            variant="standard"
            ref={emailRef}
          />
          <TextField
            label="Password"
            id="password"
            value={stateLogin.password}
            onChange={handleChange}
            type="password"
            variant="standard" 
          />
          <div ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live="assertive">
            {errMsg && <span styleClass="mt-8">{errMsg}</span>}
          </div>
          <Button
            sx={{ textTransform: 'none'}}
            type='submit'
            variant="text"
            disabled={loading}
          >
            { 
                loading &&
                <CircularProgress style={{ width: '30px', height: '30px', position: 'absolute', left: '70px' }} color="inherit" />
            }
            Se connecter
          </Button>
        </form>
      </div>
    </>
  );
}

export default Login;