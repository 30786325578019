import { Http } from '../../../applications/Http.js'

class PayrollService {
    static async getPayroll(employeeId, period, tab) {
        return await Http.get(`/api/pay/payroll?employeeId=${employeeId}&period=${period}&tab=${tab}`);
    }
    static async getAllPayroll(period) {
        return await Http.get(`/api/pay/all-payroll?period=${period}`);
    }
    static async generatePdfPaySlip(employeeId, period, tab) {
        return await Http.get(`/api/pay/print-pdf?employeeId=${employeeId}&period=${period}&tab=${tab}`);
    }
    static async generateAllPdfPaySlip(period) {
        return await Http.get(`/api/pay/print-all-pdf?period=${period}`);
    }
    static async getSalaryNetBrut(employeeId) {
        return await Http.get(`/api/pay/salary-net-brut?employeeId=${employeeId}`);
    }
    static async closePayslipMonth(period) {
        return await Http.put(`/api/pay/close-month-payroll?period=${period}`);
    }
    static async getAllPayrollClose(period) {
        return await Http.get(`/api/pay/all-payroll-close?period=${period}`);
    }
    static async generateAllExcelPaySlip(period) {
        return await Http.get(`/api/pay/export-all-payroll-excel?period=${period}`);
    }
    static async generateAllExcelPaySlipClose(period) {
        return await Http.get(`/api/pay/export-all-close-payroll-excel?period=${period}`);
    }
    static async generateAllPdfPaySlipClose(period) {
        return await Http.get(`/api/pay/print-all-close-pdf?period=${period}`);
    }
}

export default PayrollService
