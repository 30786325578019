import React from "react";
import {
  DataGrid,
  GridToolbar,
} from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import { useDemoData } from '@mui/x-data-grid-generator';

function Table (props) {
  const { columns, rows, columnGroupingModel } = props
  const { dataGridData } = useDemoData({
    dataSet: 'Mat.',
    rowLength: 10,
    maxColumns: 6,
  });
  return (
    <DataGrid
      className='customGridTable'
      localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
      columns={columns}
      rows={rows}
      slots={{ toolbar: GridToolbar }}
      columnGroupingModel={columnGroupingModel}
      // onRowSelectionModelChange={(ids) => {
      //   const selectedIDs = new Set(ids);
      //   const selectedRows = rows.filter((row) =>
      //     selectedIDs.has(row.id),
      //   );
      //   setSelectedRows(selectedRows);
      // }}
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          printOptions: { disableToolbarButton: true },
          csvOptions: { disableToolbarButton: true },
        },
      }}
      pageSizeOptions={[10, 25, 50, 100]}
      {...dataGridData}
    />
  )
}

export default Table