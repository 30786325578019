import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useAuth } from '../applications/hooks/UseAuth'

const RequireAuth = () => {
    const location = useLocation()
    const { token } = useAuth(/* reloadToken */)
    return (
        token
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    )
}
export default RequireAuth