import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { DASHBOARD_SIDEBAR_LINKS } from '../routes/menu';
import { Link } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import logo from '../assets/img/logo.png';

const drawerWidth = 260;

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderBottom: 0,
  borderTop: 0,
  '&::before': {
    display: 'none',
  },
  '& svg:not(:last-child)': {
    padding: '0px 10px 0px 0px'

  },
}));

const ParentList = styled('div')(() => ({
  '& svg': {
    padding: '0px 10px 0px 0px'
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  height: '140px',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft(props) {
  const theme = useTheme();
  const { handleDrawerClose, open } = props
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <img style={{ height: '140px', width: '200px', objectFit: 'contain' }} src={logo} alt="user" />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          {DASHBOARD_SIDEBAR_LINKS.map((item, index) => (
            item.submenu === undefined ?
                (
                  <React.Fragment key={index}>
                    <ParentList>
                      <ListItemButton
                        sx={{ maxHeight: '50px' }}
                        component={Link}
                        to={item.path}
                      >
                        {/* <ListItemIcon sx={{}}> */}
                          {item.icon}
                        {/* </ListItemIcon> */}
                        <ListItemText primary={item.label} />
                      </ListItemButton>
                    </ParentList>
                  </React.Fragment>
                )
               : (
                <React.Fragment key={index}>
                <Accordion sx={{ position: 'relative' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <ListItemButton
                      sx={{ maxHeight: '50px', padding: 0 }}
                      component="a"
                    >
                        {item.icon}
                      <ListItemText primary={item.label} />
                    </ListItemButton>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingBottom: 0, paddingTop: 0 }}>
                  {item.submenu.map((sub, i) => (
                    <React.Fragment key={i}>
                      <ListItemButton
                        sx={{maxHeight: '50px', paddingBottom: '5px', paddingTop: '5px' }}
                        component={Link}
                        to={sub.path}
                      >
                          {sub.icon}
                        <ListItemText primary={sub.label} />
                      </ListItemButton>
                    </React.Fragment>
                  ))}
                  </AccordionDetails>
                </Accordion>
                </React.Fragment>
              )
            ))}
        </Drawer>
      </Box>
    </>
  );
}