import { Http } from '../../../applications/Http.js'
import Service from '../../../applications/Service.js'

class UserService extends Service {
    static messages = {
        update_success: 'User updated.',
        update_error: 'User not updated.',
        delete_success: 'User deleted.',
        delete_error: 'User not deleted.',
        create_success: 'User created.',
        create_error: 'User not created.'
    }

    ///////////////////AUTH////////////////////////
    static async login(params) {
        return await Http.post('/api/auth/login', params)
    }

    static async setSession(params) {
        const { data, status } = await Http.put('/api/auth/session', params)
        if (status?.code === 200) {
            sessionStorage.setItem('sessID', data.sessID)
            return data
        }

        return null
    }

    static async getSession() {
        const sessID = sessionStorage.getItem('sessID')
        const { data, status } = await Http.get('/api/auth/session', { sessID })
        if (status?.code === 401) {
            sessionStorage.removeItem('sessID')
            return null
        }

        return data
    }

    static load() {
        this.setUrl('/api/users')
        return this
    }
}

export default UserService
