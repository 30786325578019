// import Notification from '../layouts/Notification'

class ErrorHandler {
    static handle(error) {
        switch (error.response?.status) {
            case 401:
                if (!['login'].some((r) => window.location.href.includes(r))) {
                    sessionStorage.clear()
                    window.location.href = '/login'
                }
                console.log('error');
                // new Notification(error.response?.data?.status?.message, 0)
                break
                default:
                console.log('error');
                // new Notification(error.response?.data?.status?.message, 0)
                break
        }
        return false
    }
}

export default ErrorHandler
