import React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import 'dayjs/locale/fr';

export default function InputTime(props) {
    const { name, label, value, onChange } = props

    const convertToDefEventPara = (name, value) => ({
        target: {
            name,
            value
        }
    })

    return (
        <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimeField']}>
                <TimeField
                  sx={{ width: '100%' }}
                  label={label}
                  value={dayjs(value)}
                  format="HH:mm"
                  name={name}
                  onChange={(hours) => onChange(convertToDefEventPara(name, hours))}
                />
            </DemoContainer>
        </LocalizationProvider>
    )
}
