import React, { useEffect, useState } from 'react';
import PointageService from './service/Pointage.jsx';
import Table from '../../components/table/index.jsx';
import moment from 'moment';
import Button from '@mui/material/Button';
// import { useAuth } from '../../applications/hooks/UseAuth';
import Notification from '../../components/notificationComponent/index.jsx';
import { CircularProgress } from '@material-ui/core';
import { LiaCloudUploadAltSolid } from 'react-icons/lia';

// const CURRENT_PAGE = 'pointage';

function Pointage() {
    // const { haveAccessTo } = useAuth();
    const [rows, setRows] = useState([]);
    const [openNotif, setOpenNotif] = useState(false);
    const [messageNotif, setMessageNotif] = useState('');
    const [severityPerso, setSeverityPerso] = useState("");
    const [isClickExport, setIsClickExport] = useState(false);

    const getListEmploye = async () => {
        const response = await PointageService.getAllPointage()
        const newRows = []
        if (response.status.code === 200) {
            response?.data?.forEach(el => {
                newRows.push({
                  id: el.id,
                  matricule: el.matricule,
                  fullName: el.lastname + ' ' + el.firstname,
                  date: moment(el.date).format('DD MMMM YYYY'),
                  startAM: el.startAM,
                  endAM: el.endAM,
                  startPM: el.startPM,
                  endPM: el.endPM,
                });
            });
        }
        setRows(newRows);
    }

    useEffect(() => {
        getListEmploye();
    }, [])

    const handleCloseNotif = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageNotif("");
        setOpenNotif(false);
        setSeverityPerso("");
    };

    const columns = [
        {
            field: 'matricule',
            headerName: 'Matricule',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'fullName',
            headerName: 'Nom et Prénoms',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'date',
            headerName: 'Date',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'startAM',
            headerName: 'Début matin',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'endAM',
            headerName: 'Fin matin',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'startPM',
            headerName: 'Début après midi',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'endPM',
            headerName: 'Fin après midi',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        }
    ]

    const onExportExcel = async () => {
        setIsClickExport(true);
        PointageService.generateExcel({}).then((res) => {
          setIsClickExport(false);
          let a = document.createElement('a');
          a.href = 'data:application/octet-stream;base64,' + res.data.content;
          a.download = `pointages.xlsx`;
          a.click();
        });
    };

    return (
        <>
            <Notification
                severity={severityPerso}
                openNotif={openNotif}
                handleClose={handleCloseNotif}
                message={messageNotif}
            />
            <div style={{ padding: 20 }}>
                <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <h4 style={{ margin: 0 }}>Pointage</h4>
                    <div>
                        <Button
                            disabled={isClickExport}
                            style={{ textTransform: 'capitalize' }}
                            variant="text"
                            size="small"
                            onClick={onExportExcel}
                        >
                            { 
                                isClickExport &&
                                <CircularProgress style={{ width: '16px', height: '16px', position: 'absolute', left: '3px' }} color="inherit" />
                            }
                            <LiaCloudUploadAltSolid className='actionIconStyle' />
                            Exporter
                        </Button>
                    </div>
                </div>
                <Table columns={columns} rows={rows}/>
            </div>
        </>
    )
}

export default Pointage;