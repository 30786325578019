import React, { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import Controls from '../../../components/Entry/Controls';
import { AddEmployeeContext } from './index.jsx';
import FormControl from '@material-ui/core/FormControl';

const typeContractItems = [
    { id: 'cdi', title: 'CDI' },
    { id: 'cdd', title: 'CDD' },
    { id: 'PE', title: "Période d'essai" }
]

const groupItems = [
    { id: 'I', title: 'I' },
    { id: 'II', title: 'II' },
    { id: 'III', title: 'III' },
    { id: 'IV', title: 'IV' },
    { id: 'V', title: 'V' }
]

// const categoryItems = [
//     { id: '1B', title: '1B' },
//     { id: '2A', title: '2A' },
//     { id: 'HC', title: 'HC' }
// ]

const serviceItems = [
    { id: 'Administration', title: 'Administration' },
    { id: 'Production', title: 'Production' }
]

const departmentItems = [
    { id: 'DRH', title: 'DRH' },
    { id: 'DAF', title: 'DAF' },
    { id: 'Maintenance', title: 'Maintenance' }
]

const statusItems = [
    //{ id: 0, title: 'Terminé' },
    { id: 1, title: 'En poste' },
    { id: 2, title: 'Démissionnaire' },
]

export default function Contract(props) {
    const { errors, setErrors, setValuesContract, valuesContract, arrayCategory } = useContext(AddEmployeeContext);

    const validate = (fieldValues = valuesContract) => {
        let temp = { ...errors }
        if ('jobTitle' in fieldValues) temp.jobTitle = fieldValues.jobTitle ? '' : 'Champ obligatoire.'
        if ('contractType' in fieldValues) temp.contractType = fieldValues.contractType ? '' : 'Champ obligatoire.'
        if ('contractStartDate' in fieldValues)
            temp.contractStartDate = fieldValues.contractStartDate ? '' : 'Champ obligatoire.'

        if ('category' in fieldValues) temp.category = fieldValues.category ? '' : 'Champ obligatoire.'
        if ('group' in fieldValues) temp.group = fieldValues.group ? '' : 'Champ obligatoire.'
        if ('baseSalary' in fieldValues) temp.baseSalary = fieldValues.baseSalary ? '' : 'Champ obligatoire.'
        if ('status' in fieldValues) temp.status = fieldValues.status ? '' : 'Champ obligatoire.'

        if (fieldValues.contractType !== 'cdi') {
            if ('contractEndDate' in fieldValues)
                temp.contractEndDate = fieldValues.contractEndDate ? '' : 'Champ obligatoire.'
        } else {
            delete temp.contractEndDate
        }

        for (const key in temp) {
            if (temp[key] === '') {
                delete temp[key]
            }
        }

        setErrors({
            ...temp
        })

        return Object.keys(temp).length === 0
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        
        if(name === 'baseSalary') {
            let newValue = value.replace(/[^\d+]/g, '');
            setValuesContract({ ...valuesContract, [name]: newValue });
        } else if(name === 'group') {
            setValuesContract({ ...valuesContract, [name]: value, category : "" });
        } else {
            setValuesContract({ ...valuesContract, [name]: value });
        }
    }

    //handle load
    // useEffect(() => {
    //     !addEmployeeData.contract
    //         ? setAddEmployeeData({
    //               ...addEmployeeData,
    //               contract: values
    //           })
    //         : setValues(addEmployeeData.contract)
    // }, [])

    useEffect(() => {
        validate()
    });

    //handle click to next
    // useEffect(() => {
    //     setAddEmployeeData({
    //         ...addEmployeeData,
    //         contract: values
    //     })
    // }, [clickBack, clickNext, values])

    return (
        <form>
            <Grid container>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}} >
                        <FormControl style={{ width: '50%', marginBottom: '30px' }}>
                            <Controls.Input
                                name="jobTitle"
                                label="Poste"
                                value={valuesContract.jobTitle}
                                onChange={handleInputChange}
                                error={errors.jobTitle}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}} >
                        <FormControl style={{ width: '50%', marginBottom: '30px' }}>
                            <Controls.Select
                                name="status"
                                label="Statut du poste actuel"
                                value={valuesContract.status}
                                onChange={(e) => {
                                    handleInputChange(e)
                                }}
                                options={statusItems}
                                error={errors.status}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={valuesContract.contractType !== 'cdi' ? 4 : 6 } sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: valuesContract.contractType !== 'cdi' ? '90%' : '50%', marginBottom: '30px' }}>
                            <Controls.Select
                                name="contractType"
                                label="Type de contrat"
                                value={valuesContract.contractType}
                                onChange={(e) => {
                                    handleInputChange(e)
                                }}
                                options={typeContractItems}
                                error={errors.contractType}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={valuesContract.contractType !== 'cdi' ? 4 : 6 } sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: valuesContract.contractType !== 'cdi' ? '90%' : '50%', marginBottom: '30px', marginTop: '-8px' }}>
                            <Controls.DatePicker
                                name="contractStartDate"
                                label="Début du contrat"
                                value={valuesContract.contractStartDate}
                                onChange={handleInputChange}
                                error={errors.contractStartDate}
                            />
                        </FormControl>
                    </Grid>
                    {valuesContract.contractType !== 'cdi' && (
                        <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center'}}>
                            <FormControl style={{ width: '90%', marginBottom: '30px', marginTop: '-8px' }}>
                                <Controls.DatePicker
                                    name="contractEndDate"
                                    label="Fin du contrat"
                                    value={valuesContract.contractEndDate}
                                    onChange={handleInputChange}
                                    error={errors.contractEndDate}
                                />
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '30px' }}>
                            <Controls.Select
                                name="group"
                                label="Groupe"
                                value={valuesContract.group}
                                onChange={(e) => {
                                    handleInputChange(e)
                                }}
                                options={groupItems}
                                error={errors.group}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '30px' }}>
                            <Controls.Select
                                name="category"
                                label="Catégorie"
                                value={valuesContract.category}
                                onChange={(e) => {
                                    handleInputChange(e)
                                }}
                                options={arrayCategory}
                                error={errors.category}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '30px' }}>
                            <Controls.Select
                                name="service"
                                label="Service"
                                value={valuesContract.service}
                                onChange={(e) => {
                                    handleInputChange(e)
                                }}
                                options={serviceItems}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '30px' }}>
                            <Controls.Select
                                name="department"
                                label="Département"
                                value={valuesContract.department}
                                onChange={(e) => {
                                    handleInputChange(e)
                                }}
                                options={departmentItems}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <FormControl style={{ width: '50%', marginBottom: '30px' }}>
                            <Controls.Input
                                name="baseSalary"
                                label="Salaire de base (en Ar)"
                                value={valuesContract.baseSalary}
                                onChange={handleInputChange}
                                error={errors.baseSalary}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}></Grid>
                </Grid>
            </Grid>
        </form>
    )
}
