import { Http } from '../../../applications/Http.js'
class RubriqueService {

    static async getAllRubrique() {
        return await Http.get('/api/rubrique/list');
    }
    static async getAllGainRetenu() {
        return await Http.get('/api/gainRetenu/list');
    }
    static async addGainRetenu(payload) {
        payload.compte = payload.compte.split('-')[0];
        return await Http.put(`/api/gainRetenu/add`, payload);
    }
    static async editGainRetenu(id, payload) {
        return await Http.put(`/api/gainRetenu/edit?id=${id}`, payload);
    }
    static async getSingleGainRetenu(id) {
        return await Http.get(`/api/gainRetenu/single-gainRetenu?id=${id}`);
    }
    static async deleteGainRetenu(id) {
        return await Http.delete(`/api/gainRetenu/delete?id=${id}`);
    }
    static async getAllEmployee() {
        return await Http.get('/api/employees/list');
    }
}

export default RubriqueService
