import React from "react";
import useStyles from './style.jsx';
import { Modal } from '@material-ui/core';
import Button from '@mui/material/Button';
import { AiFillCloseCircle } from "react-icons/ai";

function ModalComponent (props) {
  const classes = useStyles();
  const { openModal, close, title, submit, actionTitle, modalContentBody } = props
  
  return (
    <Modal open={openModal} onClose={close}>
      <div className={classes.modalContent} >
        <div className={classes.modalHeader} >
          <h5>{title}</h5>
          <AiFillCloseCircle
            className={classes.btnClose}
            onClick={() => { close() }}
          />
        </div>
        <div className={classes.modalBody}>
          {modalContentBody}
        </div>
        <div className={classes.modalFooter}>
          <Button onClick={submit}>
            {actionTitle}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalComponent;