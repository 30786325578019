import React, { useEffect } from 'react'
import { FormHelperText, TextField, Autocomplete } from '@mui/material'

const useStyles = {
    labelInput: {
        fontSize: '12px',
    },
    labelContainer: {
        marginBottom: '20px',
        width: '100%',
        display: 'block',
        
    }
}

export default function AutoCompleteSelect(props) {
    const { name, label, value, error = null, onChange, options, labelKey, valueKey, isDisabled = false, disableSorting = false } = props
    const classes = useStyles

    useEffect(() => {
        //order label alphabetically
        if(options && labelKey && !disableSorting) options.sort((a, b) => a[labelKey].localeCompare(b[labelKey]))
    }, [options, labelKey, disableSorting])

    return (
        <div style={classes.labelContainer}>
            <div style={classes.labelInput}>{label}</div>
                <Autocomplete
                    name={name}
                    value={options.find((option) => option[valueKey] === value) || null}
                    onChange={(event, newValue) => {
                        onChange({ target: { name, value: newValue ? newValue[valueKey] : '' } })
                    }}
                    options={options}
                    getOptionLabel={(option) => option[labelKey]}
                    renderInput={(params) => <TextField {...params} style={{ width: '100%' }} variant="outlined" />}
                    size="small"
                    disabled={isDisabled}
                    {...(error && { error: true, helperText: error })}
                />
                {error && <FormHelperText error>{error}</FormHelperText>}
        </div>
    )
}
