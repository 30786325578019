import { makeStyles } from '@material-ui/core/styles';

const styles = {
  modalContent: {
    top: ' 50%',
    left: '50%',
    width: '350px',
    padding: '20px',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    background: '#FFF',
    borderRadius: '5px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h5': {
      padding: 0,
      margin: 0,
    },
    '& button': {
      height: 40,
    },
  },
  modalBody: {
    margin: '20px',
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnClose: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.5
    }
  }
}

const useStyles = makeStyles(styles);

export default useStyles;