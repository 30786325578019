import { Http } from '../../../applications/Http.js'

// class EmployeeService extends Service {
class AbsService {

    static async getAllAbs() {
        return await Http.get('/api/absences/list');
    }
    // static async deleteEmployee(id) {
    //     return await Http.delete(`/api/employees/delete?id=${id}`);
    // }
    static async addAbs(payload) {
        return await Http.put(`/api/absences/add`, payload);
    }
    static async getAllEmployee() {
        return await Http.get('/api/employees/list');
    }
    static async generateExcel() {
        return await Http.get(`/api/absences/export-excel-abs`);
    }
    // static async updateEmployee(id, payload) {
    //     return await Http.put(`/api/employees/update?id=${id}`, payload);
    // }
    // static async getSingleEmploye(id) {
    //     return await Http.get(`/api/employees/single?id=${id}`);
    // }
    // static async addDocument(payload, file) {
    //     const formdata = new FormData();
    //     formdata.append('body', JSON.stringify(payload));
    //     formdata.append('file', file);
    //     return await Http.post(`/api/employees/add-document`, formdata);
    // }
    // static async deleteDocument(id) {
    //     return await Http.delete(`/api/employees/delete-document?id=${id}`);
    // }
}

export default AbsService
