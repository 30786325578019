import { Http } from '../../../applications/Http.js'

// class EmployeeService extends Service {
class sanctionService {

    static async getAllSanction() {
        return await Http.get('/api/sanction/list');
    }
    static async addSanction(payload) {
        return await Http.put(`/api/sanction/add`, payload);
    }
    static async getAllEmployee() {
        return await Http.get('/api/employees/list');
    }
    static async generateExcel() {
        return await Http.get(`/api/sanction/export-excel-sanction`);
    }
}

export default sanctionService
