// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../applications/hooks/UseAuth';
import EmployeeService from './services/Employee.jsx';
import Table from '../../components/table/index.jsx';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Chip } from '@mui/material';
import Tooltips from '../../components/tooltips/index.jsx';
import Button from '@mui/material/Button';
import { LiaEyeSolid, LiaCloudUploadAltSolid } from 'react-icons/lia';
import { MdDeleteOutline, MdAddCircleOutline } from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';
// import { FaFileInvoiceDollar } from 'react-icons/fa';
import Zoom from '@material-ui/core/Zoom';
import ModalComponent from '../../components/modalComponent/index.jsx';
import Notification from '../../components/notificationComponent/index.jsx';
import DrawerCompenent from '../../components/drawer/index.jsx';
import AddEditEmployee from '../employee/addEdit/index.jsx';
import SeeMore from './seeMore/index.jsx';
import FormControl from '@material-ui/core/FormControl';
import { CircularProgress } from '@material-ui/core';

const CURRENT_PAGE = 'employee';

function ModalContentBody(props) {
    const { valuesStatus, setValuesStatus } = props;

    return (
        <form>
            <FormControl style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '30px'}} >
                <label style={{ fontSize: '14px', }}>Status</label>
                <select
                    style={{
                        width: '100%',
                        padding: '8px',
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderRight: 'none',
                        borderBottom: '1px solid #ccc'
                    }}
                    value={valuesStatus}
                    onChange={(e) => {
                        setValuesStatus(e.target.value)
                    }}
                >
                    <option value='1'>En Poste</option>
                    <option value="2">Démissionnaire</option>
                    <option value="3">Ex-employé</option>
                    <option value="4">Renvoyer</option>
                </select>
            </FormControl>
        </form>
    )
}

function Employees() {
    const { haveAccessTo } = useAuth();
    const [rows, setRows] = useState([]);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [deletedId, setDeletedId] = useState('');
    const [openNotifList, setOpenNotifList] = useState(false);
    const [messageNotifList, setMessageNotifList] = useState('');
    const [openAddEdit, setOpenAddEdit] = useState(false);
    const [idEmployee, setIdEmployee] = useState(null);
    const [openDrawerSeeMore, setOpenDrawerSeeMore] = useState(false);
    const [employeeName, setEmployeeName] = useState(null);
    const [severityPersoList, setSeverityPersoList] =  useState("");
    const [openChangeStatus, setOpenChangeStatus] = useState(false);
    const [valuesStatus, setValuesStatus] = useState(1);
    const [isClickExport, setIsClickExport] = useState(false);

    const openEditDrawer = (id) => () => {
        setIdEmployee(id);
        setOpenAddEdit(true);
    }

    const handleSeeMore = (params) => () => {
        setIdEmployee(params.id);
        setEmployeeName(`${params.name}`);
        setOpenDrawerSeeMore(true);
    }

    const handleChangeStatus = (params) => () => {
        setIdEmployee(params.id);
        setOpenChangeStatus(true);
        setValuesStatus(params.row.status);
    }

    const columns = [
        {
            field: 'matricule',
            headerName: 'Matricule',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'name',
            headerName: 'Nom et Prénoms',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'contractType',
            headerName: 'Type de contrat',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'jobTitle',
            headerName: 'Poste',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'status',
            type: 'actions',
            headerName: 'Status',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            getActions: (params) => {
                const status = {
                    1: { title: 'En Poste', color: 'success', icon: <CheckCircleOutlineOutlinedIcon /> },
                    2: { title: 'Démissionnaire', color: 'warning', icon: <ErrorOutlineOutlinedIcon /> },
                    3: { title: "Ex-employé", color: 'info', icon: <AccessTimeOutlinedIcon /> },
                    4: { title: 'Renvoyer', color: 'error', icon: <DoNotDisturbAltOutlinedIcon /> }
                }

                return [
                    <Chip
                        onClick={handleChangeStatus(params)}
                        label={status[params.row.status]?.title}
                        color={status[params.row.status]?.color}
                        icon={status[params.row.status]?.icon}
                        size="small"
                        variant="outlined"
                    />
                ]
            }
        },
        {
            field: 'action',
            type: 'actions',
            headerName: 'Action',
            align: 'center',
            headerAlign: 'center',
            width: 200,
            getActions: (params) => {
                const handleDelete = async (id) => {
                    setOpenDeleteConfirm(true);
                    setDeletedId(id);
                }
                return [
                    <>
                        <Tooltips TransitionComponent={Zoom} title="Afficher plus">
                            <Button
                                // className='event-viewMore-btn contentBtnStyle forAllBtn'
                                disabled={!haveAccessTo(CURRENT_PAGE, 'viewEmployee')}
                                onClick={handleSeeMore(params.row)}
                            >
                                <LiaEyeSolid
                                className='actionIconStyle'

                                />
                            </Button>
                        </Tooltips>
                        <Tooltips TransitionComponent={Zoom} title="Supprimer">
                            <Button
                                // className="event-delete-btn contentBtnStyle forAllBtn"
                                // disabled={!haveAccessTo(CURRENT_PAGE, 'delete')}
                                onClick={() => {
                                    handleDelete(params.id);
                                }}
                            >
                                <MdDeleteOutline className='actionIconStyle' />
                            </Button>
                        </Tooltips>
                        <Tooltips TransitionComponent={Zoom} title="Modifier">
                            <Button
                                // className="event-edit-btn contentBtnStyle forAllBtn"
                                // disabled={!haveAccessTo(CURRENT_PAGE, 'edit')}
                                onClick={openEditDrawer(params.id)}
                            >
                                <FaRegEdit className='actionIconStyle'/>
                            </Button>
                        </Tooltips>
                    </>
                ]
            }
        },
    ]

    const getListEmploye = async () => {
        const response = await EmployeeService.getAllEmployee()
        const newRows = []
        if (response.status.code === 200) {
            response?.data?.forEach(el => {
                newRows.push({
                  id: el.id,
                  matricule: el.matricule,
                  name: el.name,
                  contractType: el.contractType.toUpperCase(),
                  jobTitle: el.jobTitle,
                  status: el.status,
                });
            });
        }
        setRows(newRows);
    }

    useEffect(() => {
        getListEmploye();
    }, [])

    const confirmDelete = async () => {
        const response = await EmployeeService.deleteEmployee(deletedId);
        if(response.status.code === 200) {
            setDeletedId('');
            setSeverityPersoList("success")
            setMessageNotifList("L'employé a été supprimé avec succès.");
            setRows(rows.filter((el) => el.id !== deletedId));
            setOpenDeleteConfirm(false);
            setOpenNotifList(true);
        }
    }

    const changeStatus = async () => {
        const response = await EmployeeService.updateStatus(idEmployee, valuesStatus);
        if(response.status.code === 200) {
            setIdEmployee('');
            setSeverityPersoList("success")
            setMessageNotifList("Status modifier.");
            setRows(
                rows.map((row) => {
                    return row.id === response.data.id ? {...row, status: response.data.status} : row;
                })
            );
            setOpenChangeStatus(false);
            setOpenNotifList(true);
        }
    }

    const handleCloseNotif = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNotifList(false);
    };

    const onExportExcel = async () => {
        setIsClickExport(true);
        EmployeeService.generateExcel({}).then((res) => {
          setIsClickExport(false);
          let a = document.createElement('a');
          a.href = 'data:application/octet-stream;base64,' + res.data.content;
          a.download = `employes.xlsx`;
          a.click();
        });
      };

    return (
        <>
            <div style={{ padding: 20 }}>
                <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <h4 style={{ margin: 0 }}>Gestion des Employés</h4>
                    <div>
                        <Button
                            disabled={isClickExport}
                            style={{ textTransform: 'capitalize' }}
                            variant="text"
                            size="small"
                            onClick={onExportExcel}
                        >
                            { 
                                isClickExport &&
                                <CircularProgress style={{ width: '16px', height: '16px', position: 'absolute', left: '3px' }} color="inherit" />
                            }
                            <LiaCloudUploadAltSolid className='actionIconStyle' />
                            Exporter
                        </Button>
                        <Button
                            style={{ textTransform: 'capitalize' }}
                            variant="text"
                            size="small"
                            onClick={() => setOpenAddEdit(true)}
                        >
                            <MdAddCircleOutline className='actionIconStyle' />
                            Ajouter
                        </Button>
                    </div>
                </div>
                <Table columns={columns} rows={rows}/>
            </div>
            <Notification
                severity={severityPersoList}
                openNotif={openNotifList}
                handleClose={handleCloseNotif}
                message={messageNotifList}
            />
            <ModalComponent
                openModal={openDeleteConfirm}
                close={() => setOpenDeleteConfirm(false)}
                title={'Suppression'}
                submit={() => confirmDelete()}
                actionTitle={'Confirmer'}
                modalContentBody={'Est-ce que vous voulez vraiment supprimer cet employé?'}
            />
            <ModalComponent
                openModal={openChangeStatus}
                close={() => {
                        setOpenChangeStatus(false);
                        setIdEmployee(null);
                    }}
                title={'Status'}
                submit={() => changeStatus()}
                actionTitle={'Confirmer'}
                modalContentBody={<ModalContentBody valuesStatus={valuesStatus} setValuesStatus={setValuesStatus}/>}
            />
            <DrawerCompenent
                title={idEmployee === null ? 'Ajout' : 'Mofidication'}
                openDrawer={openAddEdit}
                toggleDrawer={()=> {
                    setOpenAddEdit(false);
                    setIdEmployee(null);
                }}
            >
                <AddEditEmployee
                    setIdEmployee={setIdEmployee}
                    idEmployee={idEmployee}
                    getListEmploye={getListEmploye}
                    toggleDrawer={()=> setOpenAddEdit(false)}
                    setSeverityPersoList={setSeverityPersoList}
                    setOpenNotifList={setOpenNotifList}
                    setMessageNotifList={setMessageNotifList}
                />
            </DrawerCompenent>
            <DrawerCompenent
                title={employeeName}
                openDrawer={openDrawerSeeMore}
                toggleDrawer={()=> {
                    setOpenDrawerSeeMore(false);
                    setIdEmployee(null);
                }}
            >
                {/* <AddEditEmployee
                    setIdEmployee={setIdEmployee}
                    idEmployee={idEmployee}
                    getListEmploye={getListEmploye}
                    toggleDrawer={()=> setOpenAddEdit(false)}
                /> */}
                <SeeMore idEmployee={idEmployee} />
            </DrawerCompenent>
        </>
    )
}

export default Employees
