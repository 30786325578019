import { Route, Routes } from 'react-router-dom';
import routes from '../routes/index.jsx';

function PageContent() {
    return (
        <Routes>
            {routes.map((route, key) => {
                return <Route key={key} exact={true} path={`${route.path}`} element={<route.component />} />
            })}
        </Routes>
    )
}

export default PageContent
